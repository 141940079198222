.comment-respond {
    padding: 40px 0;

    @media (max-width: 800px) {
        padding: 20px 0;
    }

    h3 {
        display: none !important;
    }

    .comment-form {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .logged-in-as {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;

            .user-logged-in {
                 span {
                    color: $beige;
                }
            }

            .user-buttons {
                display: flex;
                flex-direction: row;
                gap: 20px;
                justify-content: center;

                @media (max-width: 450px) {
                    flex-direction: column;
                }

                button {
                    width: auto;
                    flex: 0 0 auto;
                    margin: 0 !important;
                }
            }
        }
    }

    .comment-form-rating {
        @extend .comment-form;

        .stars {
            margin: 0;

            span {
                display: block;
                font-size: 0;
                text-align: center;

                a {
                    color: $light-grey;
                    height: 1.5rem;
                    width: 1.75rem;

                    &:hover ~ a {
                        color: $light-grey;
                    }

                    &:before {
                        content: "\e020" !important;
                        font-size: 1.5rem;
                    }
                }
            }

            &:hover {
                a {
                    color: $beige;
                }
            }

            &.selected {
                .active,
                a:not(.active) {
                    color: $beige;
                }

                .active ~ a {
                    color: $light-grey;
                }
            }
        }
    }

    .comment-form-comment {
        @extend .comment-form;
        margin: 0;

        textarea {
            width: 100%;
        }
    }

    .form-submit {
        margin: 0;

        button {
            width: 200px;
        }
    }

    button {
        @extend .button;
        background-color: $white !important;
        border: 1px solid $black !important;
        color: $black !important;
        font-family: $body !important;
        border-radius: 0 !important;
        font-size: 0.59rem !important;
        width: 100%;
        padding: 18px !important;
        font-weight: normal !important;
    }

    .required {
        color: $beige;
    }
}