.dashboard {

  &__container {
    @extend .container;
    @extend .row-space-between;
    gap: 40px;
    padding: 40px 15px;

    @media (max-width: 1160px) {
      flex-direction: column-reverse;
      justify-content: start;
    }

    @media (max-width: 600px) {
      padding: 20px 15px;
    }
  }

  &__navigation {
    flex: 0 0 300px;

    &--menu {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      justify-content: start;
      position: sticky;
      top: 40px;

      @media (max-width: 600px) {
        top: 20px;
      }

      li {
        text-align: center;
        border-bottom: 2px solid $light-beige;

        &:last-child {
          border-bottom: 0;
        }

        a {
          font-family: $headings;
          font-weight: 500;
          letter-spacing: 0.1rem;
          background-color: $beige;
          color: $black;
          display: block;
          text-decoration: none;
          text-transform: uppercase;
          padding: 4px 0;

          &.active {
            filter: brightness(80%);
          }
        }
      }
    }
  }

  &__content {
    @extend .column;
    gap: 40px;
    flex: 1 1 auto;

    @media (max-width: 600px) {
      gap: 20px;
    }
  }

  &__welcome {
    @extend .row-space-between;
    gap: 40px;
    border-bottom: 1px solid $light-beige;
    padding-bottom: 40px;

    @media (max-width: 600px) {
      gap: 20px;
      flex-direction: column;
      justify-content: start;
      text-align: center;
      padding-bottom: 30px;
    }

    .dashboard__heading {
      @extend .item-vertical-center;
    }

    .button {
      @extend .button;
      @extend .button--transparent;
      border-radius: 0 !important;
      font-size: .56rem !important;
      font-weight: 400 !important;
      padding: 15px 45px !important;
      width: 240px;

      @media (max-width: 600px) {
        display: block !important;
        margin: 0 auto !important;
      }
    }

  }

  &__heading {
    margin: 0;

    @media (max-width: 600px) {
      text-align: center;
    }
  }

  &__description {
    margin: 0 !important;
    font-family: $headings !important;

    a {
      color: $beige;
      text-decoration: none;
    }
  }

  &__orders-list {
    @extend .column;
    gap: 40px;

    @media (max-width: 600px) {
      gap: 20px;
    }

    &--pagination {
      @extend .row-space-between;
      gap: 15px;

      @media (max-width: 600px) {
        flex-direction: column;
      }
    }

    &--number {
      @extend .item-vertical-center;
      line-height: 2.04rem;

      @media (max-width: 600px) {
        display: block;
        text-align: center;
      }
    }

    &--navigation {
      @extend .row;
      gap: 8px;

      @media (max-width: 600px) {
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }

  &__order {
    @extend .row-space-between;
    gap: 20px;
    padding-bottom: 40px;
    border-bottom: 1px solid $light-beige;

    @media (max-width: 600px) {
      flex-wrap: wrap;
      padding-bottom: 20px;
    }

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: 0;
    }

    &--picture {
      width: 150px;
      height: 150px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--info {
      @extend .column;
      justify-content: center;
      line-height: 1.4;
      flex: 1;
    }

    &--number {
      color: $beige;
      font-family: $headings;
      font-size: 2rem;
      line-height: 1.25;
      text-decoration: none;
    }

    &--date {
      font-weight: 700;
    }

    &--status {
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 50px;
      font-size: 0.72rem;
      line-height: 2.25;

      &.failed {
        background-color: darken(#FF0000, 5%);
        color: #FFFFFF;
      }

      &.pending, &.processing {
        background-color: darken(#FFC000, 5%);
        color: #FFFFFF;
      }

      &.completed {
        background-color: darken(#00FF00, 5%);
        color: #FFFFFF;
      }
    }

    &--actions {
      @extend .row-space-between;
      gap: 8px;
      position: relative;

      @media (max-width: 1000px) {
        flex-direction: column;
        justify-content: space-between;
      }

      @media (max-width: 600px) {
        flex-direction: row;
        flex: 0 0 100%;
      }

      .button {
        @extend .item-vertical-center;
        display: flex !important;
        height: 45px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        flex: 1;

        @media (max-width: 1000px) {
          top: 0;
          transform: none;
        }
      }
    }
  }

  &__single-order {
    @extend .column;
    gap: 40px;

    &--intro {
      @extend .row-space-between;
      gap: 40px;
    }
  }

  &__order-details {
    table {
      thead {
        @media (max-width: 1000px) {
          border-top: 0 !important;
        }
      }

      tr {
        @media (max-width: 1000px) {
          border-top: 1px solid $beige !important;
        }

        td.product-total {
          padding: 40px 20px !important;
        }
      }
    }

    .product-total {
      text-align: right !important;

      .amount {
        font-style: italic;
      }
    }

    .order__info {
      @extend .row-space-between;
      gap: 20px;
    }

    tfoot {
      tr:last-child {
        th {
          background-color: $beige !important;
          font-size: 1rem !important;
        }

        td {
          background-color: $beige !important;
          font-size: 1rem !important;
        }
      }

      td {
        font-weight: 400 !important;
        font-style: italic !important;
        text-align: right !important;
      }
    }
  }

  &__addresses {
    @extend .column;
    gap: 40px;

    @media (max-width: 600px) {
      gap: 20px;
    }

    &--cols {
      @extend .row-space-between;
      gap: 40px;

      @media (max-width: 600px) {
        flex-direction: column;
        gap: 20px;
        margin-bottom: 40px;
      }
    }

    &--col {
      @extend .column;
      justify-content: space-between;
      gap: 20px;
      flex: 1 1 50%;

      @media (max-width: 600px) {
        margin-top: 40px;
      }

      &-top {
        @extend .column;
        gap: 20px;
      }

      &-heading {
        margin: 0;

        @media (max-width: 600px) {
          text-align: center;
        }
      }
    }

    &--address {
      @media (max-width: 600px) {
        text-align: center;
      }
    }

    &--empty {
      font-style: italic;

      @media (max-width: 600px) {
        text-align: center;
      }
    }

    &--edit {
      @extend .button;
      @extend .button--transparent;
      @extend .transition--slow;

      font-weight: 400 !important;

      @media (max-width: 600px) {
        display: block !important;
        max-width: 100%;
        width: max-content !important;
        margin: 0 auto !important;
      }
    }
  }

  &__form {
    flex-direction: column;
    gap: 20px;

    &--wrapper {
      .select2-hidden-accessible {
        border: 1px solid $beige !important;
        clip: unset !important;
        width: 100% !important;
        height: auto !important;
        position: relative !important;
        margin: auto !important;
        padding: 13px !important;
      }

      .select2-container {
        display: none !important;
      }

      h3 {
        font-size: 1rem;
        margin-top: 40px;
      }
    }

    button {
      @extend .button--transparent;
      @extend .transition--slow;

      font-weight: 400 !important;
    }
  }

  &__wishlist {
    @extend .column;
    gap: 40px;

    &--module {
      @extend .column;
      border: 1px solid $beige;
      flex: 1;

      &-container {
        @extend .column;
        gap: 15px;
        padding: 15px;
      }

      &-top {
        @extend .row-space-between;

        h3 {
          @extend .item-vertical-center;
        }

        button {
          width: 160px;
        }
      }

      &-rename {
        @extend .row-space-between;
        max-height: 0;
        overflow: hidden;
        transition: max-height 1s ease;
        gap: 15px;

        &.open {
          max-height: 100%;
        }

        input {
          flex: 1;
        }

        button {
          @extend .button--transparent;
          @extend .transition--slow;

          width: 160px;
          font-weight: 400 !important;
        }
      }

      &-created {
        @extend .item-vertical-center;
        flex: 1;
        margin: 0;
        font-size: 0.82rem;

        @media (max-width: 1200px) {
          flex: 1 0 auto;
          width: 100%;
        }

        @media (max-width: 1000px) {
          width: auto;
        }

        @media (max-width: 800px) {
          width: 100%;
        }
      }

      &-actions {
        @extend .row;
        justify-content: end;
        gap: 15px;
        align-items: center;

        @media (max-width: 1200px) {
          flex-wrap: wrap !important;
        }

        @media (max-width: 1000px) {
          flex-wrap: nowrap !important;
        }

        @media (max-width: 800px) {
          flex-wrap: wrap !important;
        }

        @media (max-width: 600px) {
          flex-direction: column !important;
          justify-content: start !important;
        }

        button {
          @extend .button--transparent;
          @extend .transition--slow;

          font-weight: 400 !important;
        }

        & > * {
          @media (max-width: 600px) {
            width: 100% !important;
          }
        }
      }

      &-remove {
        @extend .item-vertical-center;

        a.remove {
          @extend .transition--slow;

          position: relative !important;
          background-color: transparent !important;
          z-index: 3 !important;

          @media (max-width: 600px) {
            left: 50% !important;
            transform: translateX(-50%) !important;
          }

          &:hover {
            &:before {
              width: 100% !important;
              height: 100% !important;
            }
          }

          &:before {
            @extend .transition--slow;

            content: "";
            display: block !important;
            position: absolute !important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            background-color: var(--wc-red) !important;
            width: 0 !important;
            height: 0 !important;
            border-radius: 100% !important;
            z-index: -1 !important;
          }
        }
      }
    }

    &--create {
      form {
        @extend .row;
        gap: 15px;

        input {
          @media (max-width: 1000px) {
            flex: 1;
          }
        }
      }

      button {
        @extend .button;
        @extend .button--transparent;
        @extend .transition--slow;

        width: 160px;
        font-weight: 400 !important;
      }
    }

    &--add-to-cart {
      @extend .row-space-between;
      justify-content: end !important;
      flex-wrap: wrap;
      gap: 5px;

      &:before {
        display: none !important;
      }

      .add_to_cart_button {
        flex: 0 0 188px;
      }

      .added_to_cart {
        @extend .button;
        @extend .button--transparent;
        flex: 0 0 140px;
        line-height: 1 !important;
      }
    }

    &--fw {
      padding: 0 !important;
    }

    table {
      td {
        padding: 20px 15px !important;

        &.dashboard__wishlist--fw {
          padding: 0 !important;
        }
      }

      .product {
        form {
          @media (max-width: 770px) {
            padding-top: 18px;
          }

          .remove {
            margin-left: 1155px;

            @media (max-width: 1630px) {
              margin-left: calc(100vw - 405px - 60px);
            }

            @media (max-width: 1160px) {
              margin-left: calc(100vw - 65px - 60px);
            }
          }
        }
      }

      .remove {
        @media (max-width: 770px) {
          margin-left: 0 !important;
          width: 100% !important;
          margin-top: 0 !important;
        }
      }

      /*

      .product {
        @media (max-width: 1050px) {
          width: 120px;
        }

        &__details {
          @media (max-width: 1300px) {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
        }

        &__title {
          @media (max-width: 1300px) {
            padding: 0;
          }
        }
      }

      */

      .product {
        &__title {
          @media (max-width: 1000px) {
            padding: 0;
          }

          @media (max-width: 768px) {
            justify-content: start !important;
          }
        }
      }

      .price {
        padding: 40px 20px !important;
        text-align: right;

        @media (max-width: 1000px) {
          padding: 20px !important;
        }
      }

      .fw-button {
        top: 20px;
      }

      .remove {
        right: unset !important;
        margin-right: -5px !important;

        @media (max-width: 768px) {
          padding: 0 0 20px 0 !important;
          margin-right: -20px !important;
        }
      }
    }
  }
}