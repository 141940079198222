select, .select {
  @extend .input--text;
  @extend .transition--slow;
  background-color: $beige !important;
  font-size: 0.56rem !important;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.15rem !important;
  padding: 13px !important;
  width: 100% !important;
  cursor: pointer;
  border: 0 !important;
  color: $black !important;
  border-radius: 0 !important;

  &:hover {
    background-color: darken($beige, 5%) !important;
  }

  &:focus {
    background-color: $beige !important;
  }

  option {
    @extend .input--text;

    font-size: 0.56rem !important;
    text-transform: uppercase !important;
    text-align: left !important;
    letter-spacing: 0.15rem !important;
    padding: 20px !important;
  }

  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;

  &::-ms-expand {
    display: none; /* Remove arrow in Internet Explorer */
  }

  background-image: url('../images/icon-filter.svg') !important;
  background-repeat: no-repeat !important;
  background-position: 97% center !important;
  background-size: auto 24px !important;

  &--white{
    background-color: $white !important;
  }
}

.custom-select {
  @extend select;

  width: 240px !important;
  padding: 0 !important;
  background-image: none !important;
  line-height: 0 !important;
  position: relative !important;

  &:hover, &:focus {
    background-color: $beige !important;
  }

  button {
    @extend .input--text;
    @extend .transition--slow;

    background-color: transparent !important;
    background-image: url('../images/icon-filter.svg');
    background-repeat: no-repeat !important;
    background-position: 97% center !important;
    background-size: auto 24px !important;
    border: 0 !important;
    padding: 13px !important;
    font-size: 0.56rem !important;
    text-transform: uppercase !important;
    text-align: left !important;
    letter-spacing: 0.15rem !important;
    width: 100% !important;

    &:hover {
      background-color: darken($beige, 5%) !important;
      color: $black !important;
    }

    &:focus {
      background-color: $beige !important;
      color: $black !important;
    }
  }

  div {
    background-color: $beige !important;
    border-top: 2px solid $light-beige !important;
    position: absolute !important;
    width: 100% !important;

    button {
      background-image: none !important;
    }
  }
}