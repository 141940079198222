.product-reviews {
    &__heading {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 50px;
    }

    &__ratings {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: nowrap;
        margin-bottom: 100px;

        @media (max-width: 1200px) {
            flex-direction: column;
            justify-content: center;
            gap: 50px;
            margin-bottom: 50px;
        }

        @media (max-width: 800px) {
            margin-bottom: 40px;
        }

        & > * {
            padding: 0 60px;
            border-right: 1px solid $beige;

            @media (max-width: 1200px) {
                padding: 0 !important;
                border: 0 !important;
            }
        }

        & > *:first-child {
            padding-left: 0;
        }

        & > *:last-child {
            padding-right: 0;
            border-right: 0;
        }
    }

    &__average {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: nowrap;

        .product-rating {
            margin: 0 auto;
        }

        &__rating {
            font-size: .82rem;
            line-height: 1.5;
            text-align: center;
            margin-top: 5px;

            span {
                font-size: 1.02rem;
            }
        }
    }

    &__add {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: nowrap;

        button {
            background: $white !important;
            border: 1px solid $black !important;
            @extend .button;
            color: $black !important;
            font-family: $body !important;
            border-radius: 0 !important;
            font-size: 0.59rem !important;
            width: 100%;
            padding: 18px !important;
            font-weight: normal !important;
            transition: all 500ms ease;

            @media (max-width: 1200px) {
                width: max-content;
                margin: 0 auto;
            }

            &.open {
                background: $black !important;
                color: $white !important;
            }
        }
    }

    &__write {
        max-height: 0;
        transition: max-height 1000ms ease;
        overflow: hidden;

        &.open {
            max-height: 1500px;
            border-top: 1px solid $light-grey;
        }

        .must-log-in {
            text-align: center;

            a {
                color: $beige;
                text-decoration: none;
            }
        }
    }

    &__filter {
        @extend .transition;
        width: 240px;
        display: block;
        margin: 0 auto 60px;

        @media (max-width: 800px) {
            margin: 0 auto 40px;
        }

        &:hover {
            background-color: darken($beige, 5%);
        }

        &:focus {
            background-color: $beige;
        }
    }

    &__list {
        @extend .transition--slow;

        opacity: 1;

        &.loading {
            opacity: 0.25;
        }
    }

    &__review {
        border-top: 1px solid $light-grey;
        padding: 40px 0 50px 0;


        @media (max-width: 800px) {
            padding: 20px 0 40px 0;
        }

        &__top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: nowrap;
            margin-bottom: 40px;
        }

        &__rating {
            position: relative;
            cursor: default;
            user-select: none;

            * {
                font-family: WooCommerce;
                color: $beige;
                letter-spacing: .1rem;
                font-size: .82rem;
                line-height: 1;
            }

            &__base {
                position: relative;
                z-index: 1;
            }

            &__fill {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                z-index: 2;
            }
        }

        &__date {
            font-size: 0.62rem;
            letter-spacing: 0.1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: nowrap;
        }

        &__author {
            display: flex;
            flex-direction: row;
            justify-content: start;
            flex-wrap: nowrap;
            gap: 15px;
            margin-bottom: 30px;

            * {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-wrap: nowrap;
            }

            img {
                width: 40px;
                height: auto;
            }

            span {
                font-size: 0.82rem;
                text-transform: capitalize;
            }
        }

        &__comment {
            font-size: 0.82rem;
            margin-top: 0;
        }
    }

    button {
        @extend .button;
        @extend .transition--slow;
        background-color: $white !important;
        border: 1px solid $black !important;
        color: $black !important;
        font-family: $body !important;
        border-radius: 0 !important;
        font-size: 0.59rem !important;
        width: 240px !important;
        padding: 18px !important;
        font-weight: normal !important;
        display: block !important;
        margin: 0 auto !important;

        &:hover {
            background-color: $black !important;
            color: $white !important;
        }

        &[disabled] {
            background-color: black !important;
            color: $white !important;
        }
    }
}