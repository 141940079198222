



.payment-method {
  button {
    @extend .button;
    @extend .button--black;
  }
}

.woocommerce-Payment {
  background-color: transparent !important;
  border-radius: 0 !important;

  .woocommerce-PaymentMethods {
    padding: 0 !important;
    border-bottom: 0 !important;
  }

  .woocommerce-PaymentMethod {
    input {
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      appearance: none !important;
      outline: none !important;
      width: .72rem !important;
      height: .72rem !important;
      border: 2px solid $beige !important;
      background: 0 0 !important;
      border-radius: 0 !important;
      min-height: 16px !important;
      min-width: 16px !important;
      transform: none !important;
      position: relative !important;

      &:checked {
        &:before {
          content: "";
          display: block;
          height: .75em;
          left: 50%;
          margin: 0;
          position: absolute;
          top: 50%;
          transform: translate(-50%,-50%);
          width: .75em;
          min-height: 8px !important;
          min-width: 8px !important;
          background: $beige !important;
          border-radius: 0 !important;
        }
      }
    }
  }

  .form-row {
    padding: 1em 0 0 0 !important;
  }

  .woocommerce-PaymentBox {
    background-color: $light-beige !important;
    margin-bottom: 0 !important;

    &:before {
      border: 1em solid $light-beige !important;
      border-right-color: transparent !important;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
    }

    .testmode-info {
      font-size: 0.72rem !important;
      margin-top: 0 !important;
      margin-bottom: 1em !important;
    }

    fieldset {
      border: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}