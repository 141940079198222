.product-collection {
  padding: 90px 0 45px 0;

  &__container {
    @extend .container;
  }

  &__heading {
    text-align: center;
    margin: 0 auto 45px auto;
  }

  &__products {
    @extend .product-grid;

    .product-card {
      &__footer, &__box {
        margin-left: 0;
      }
    }
  }

  &__buttons {
    margin: 45px auto 0;
    text-align: center;
  }

  &__button {
    @extend .button;
    @extend .button--black;
    margin: 0 auto;
  }
}