@keyframes throbber {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.dyr {
  padding: 0 30px;
  position: relative;
  z-index: 2;

  &__before {
    padding: 0 30px;

    &--heading {
      margin: 60px 0;
      text-align: center;
      text-transform: uppercase;
    }
  }

  &__container {
    @extend .container;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    max-width: clamp(420px, 100%, 1400px);
    padding: 0;
    position: relative;

    @media (max-width: 1260px) {
      min-height: 600px;
    }

    &.open {
      overflow-x: hidden;
      overflow-y: scroll;
    }

    &.upload {
      background-color: $light-beige;
      cursor: pointer;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 3;
      }
    }

    &.instructions {
      justify-content: center;
      gap: 100px;
      padding: 20px 0;

      @media (max-width: 1000px) {
        gap: 50px;
      }

      @media (max-width: 450px) {
        gap: 25px;
      }
    }
  }

  &__loading {
    position: absolute;
    z-index: -20;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    transition: all 0.5s;
    opacity: 0;

    &.active {
      z-index: 500;
      opacity: 1;

      span {
        animation-name: throbber;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }
  }

  &__instruction {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 10px;
    min-width: 200px;

    font-size: 0.75rem;
    line-height: 1.5;
    text-align: center;

    @media (max-width: 750px) {
      min-width: auto;
      width: 150px;
      font-size: 0.65rem;
    }

    span {
      display: block;
      background-color: $beige;
      font-size: 1.5rem;
      line-height: 2.5rem;
      width: 2.5rem;
      max-height: 2.5rem;
      text-align: center;
      border-radius: 50%;

      @media (max-width: 750px) {
        font-size: 1.25rem;
        line-height: 2rem;
        width: 2rem;
      }
    }
  }
}

.design-your-room {
  display: block;
  position: relative;
  z-index: 250;
  width: 100%;

  &__upload {
    display: block;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    padding: 200px 0;

    &--interface {
      text-align: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 25px;

      i {
        font-size: 6rem;
      }
    }

    input, button {
      display: none;
    }
  }

  &__main {
    flex: 1;

    display: block;
    height: max-content;
    position: sticky;
    top: 20px;
    width: 100%;
  }

  &__navigation {
    max-width: 400px;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: 600px;
    height: 100%;
    z-index: 300;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;

    @media (max-width: 1260px) {
      background-color: $white;

      height: 100vh;
      max-height: none;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(100%, 0);
      opacity: 0;
      transition: all 0.5s ease;
    }

    &.open {
      @media (max-width: 1260px) {
        transform: translate(0, 0);
        opacity: 1;
      }
    }

    &--container {
      padding: 25px;
      flex: 2 0 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 20px;
      left: -100%;

      @media (max-width: 460px) {
        padding: 25px 0;
      }

      &.basket {
        left: 0;
      }

      &.results {
        left: 0;
      }

      &#basket {
        &.results {
          display: none;
        }
      }

      &#results {
        &.not-visible {
          display: none;
        }

        &.results {
          display: flex;
        }
      }
    }

    &--heading {
      font-size: 1.25rem;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
    }

    &--options {
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap: 20px;
    }
  }

  &__back, &__close {
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;
    cursor: pointer;

    &:before {
      content: "\00AB";
      font-size: 1.5rem;
      line-height: 1rem;
      display: block;
      margin-top: -5px;
    }
  }

  &__close {
    display: none;

    @media (max-width: 1260px) {
      display: flex;
    }
  }

  &__colour-list {
    display: none;
  }

  &__colour-item {
    cursor: pointer;

    &--title {
      display: block;
      margin-left: 40px;
    }
  }

  &__category-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    display: none;
  }

  &__category-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    cursor: pointer;

    &--picture {
      width: 100%;
      font-size: 0;

      img {
        width: 100%;
        height: auto;
      }
    }

    &--title {
      font-family: $headings;
      line-height: 1;
    }
  }

  &__results {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: start;
    gap: 20px;
    width: 100%;
    max-height: 640px;

    .product-card {
      flex: 1 0 25%;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.3s;
      padding: 0;

      &:hover {
        opacity: 0.8;
      }

      &__box {
        margin-left: 0;
      }

      &__image {
        height: 100%;

        &--container {
          font-size: 0;
          height: 100%
        }
      }

      &__footer {
        margin-left: 0;

        h3 {
          margin: 10px 0 0;
        }

        span {
          font-size: 1rem;
          font-style: normal;
        }
      }
    }

    &--more {
      grid-column: 1 / span 2;
    }
  }

  &__option {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 20px;

    &--heading {
      display: flex;
      align-items: center;
      gap: 20px;
      cursor: pointer;
    }

    &.open {
      .design-your-room__expand {
        &:before {
          transform: rotate(90deg);
        }
      }

      .design-your-room__category-list {
        display: grid;
      }
    }
  }

  &__search {
    &.open {
      .design-your-room__search--input {
        display: block;
      }
    }

    &--input {
      display: none;
    }

    &--results {
      display: none;
    }

    .search__input {
      width: 100%;
    }
  }

  &__colour {
    &.open {
      .design-your-room__colour-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5em 0;
      }
    }
  }

  &__category {
    &.open {
      .design-your-room__add {
        display: block;
      }
    }
  }

  &__expand {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;

    &:before, &:after {
      content: '';
      width: 1px;
      height: 100%;
      background-color: $black;
      position: absolute;
      left: 50%;
      top: 0;
      display: block;
      transition: transform 0.5s ease;
    }

    &:after {
      transform: rotate(90deg);
    }
  }

  &__basket {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;

    &--list {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
    }

    &--item {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      &[data-type="variable"] {
        .bis-dyr-form {
          display: none;
        }
      }

      &__left {
        font-size: 0;

        img {
          width: 120px;
          height: auto;
        }
      }

      &__right {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: space-between;

        h3 {
          margin: 0;

          a {
            color: $black;
            text-decoration: none;
          }
        }
      }

      .bis-dyr-form {
        display: flex;
        flex-direction: column;
        gap: 1em;
        width: 100%;

        &__header {
          font-size: 0.82rem;
          line-height: 1.5;
          display: block;

          a {
            color: $beige !important;
            text-decoration: none !important;
          }
        }

        &__submit {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;

          input {
            font-size: 0.82rem !important;
            line-height: 1 !important;
            padding: 15px 20px !important;
            flex: 1 1 auto !important;
            width: 100% !important;
          }

          button {
            flex: 1 0 100px;
            padding: 15px 5px !important;
          }
        }

        .notice {
          display: block;
          width: 100%;
          padding: 15px;
          text-align: center;
          margin-bottom: 0;

          a {
            color: darken($beige, 10%) !important;
          }
        }
      }

      .select {
        width: 100%;

        &.selection-required {
          border: 2px solid #ff0000;
          box-shadow: 0 0 15px #ff0000;
        }
      }

      .remove-from-basket {
        width: 100%;
      }
    }
  }

  &__container {
    position: relative;
    display: block;
    width: 100%;
  }

  &__background {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    z-index: 0;
    font-size: 0;

    img {
      position: relative;
      width: 100%;
      height: auto;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      pointer-events: none;
    }
  }

  &__open {
    display: none;
    position: absolute;
    left: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 2;

    @media (max-width: 1260px) {
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap: 20px;
    }

    span {
      color: $black;
      position: relative;
      display: flex;
      align-items: center;
      gap: 15px;

      @media (max-width: 500px) {
        font-size: 0.75rem;
        gap: 12.5px;
      }

      &:before {
        content: "\002B";
        background-color: $beige;
        color: $black;
        display: block;
        font-size: 1.5rem;
        line-height: 2rem;
        width: 2rem;
        text-align: center;

        @media (max-width: 500px) {
          font-size: 1.125rem;
          line-height: 1.5rem;
          width: 1.5rem;
        }
      }
    }
  }

  &__loading {
    animation-name: throbber;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  &__box {
    background-image: url('/');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    border: 1px transparent;
    display: block;
    position: absolute !important;
    z-index: 0;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    cursor: pointer;

    &--tools {
      display: none;
      background-color: $light-beige;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
      position: absolute;
      bottom: -1.25rem;
      left: 50%;
      transform: translate(-50%, 100%);
      width: 300px;
      padding: 0 15px;

      @media (max-width: 500px) {
        width: 225px;
        padding: 0 11.25px;
        bottom: -1rem;
        gap: 15px;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        width: 0;
        height: 0;
        border-width: 0 20px 20px 20px;
        border-style: solid;
        border-color: transparent transparent $light-beige transparent;

        @media (max-width: 500px) {
          border-width: 0 15px 15px 15px;
        }
      }

      &.active {
        display: flex;
      }

      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;

        width: 50%;
        font-size: 0.82rem;
        line-height: 1;
        cursor: pointer;
        padding: 20px 0;

        @media (max-width: 500px) {
          font-size: 0.615rem;
          padding: 15px 0;
        }

        img {
          background: $beige;
          padding: 10px;
          width: 60px;
          height: 60px;

          @media (max-width: 500px) {
            padding: 7.5px;
            width: 45px;
            height: 45px;
          }
        }
      }
    }

    .ui-resizable-e, .ui-resizable-s {
      display: none !important;
    }

    .ui-resizable-se {
      background-color: $beige;
      background-image: none;
      display: none !important;
      font-size: 0.82rem;
      color: $black;
      text-align: center;
      width: 1.25rem;
      height: 1.25rem;
      line-height: 1.25rem;
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%);
      cursor: se-resize;
      position: absolute;

      &:before {
        content: "\f424";
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        text-indent: 0 !important;
        display: block;
        transform: rotate(90deg);
      }
    }

    .ui-rotatable-handle {
      background-color: $beige;
      background-image: none;
      display: none;
      font-size: 0.82rem;
      color: $black;
      text-align: center;
      width: 1.25rem;
      height: 1.25rem;
      line-height: 1.25rem;
      position: absolute;
      top: 0;
      bottom: unset;
      left: unset;
      right: 0;
      transform: translate(50%, -50%);
      cursor: grab;

      &:before {
        content: "\f2f9";
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
      }
    }

    &--remove {
      background-color: $beige;
      font-size: 0.82rem;
      display: none;
      color: $black;
      text-align: center;
      width: 1.25rem;
      height: 1.25rem;
      line-height: 1.25rem;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-50%, 50%);
      cursor: pointer;

      &:before {
        content: "\f1f8";
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
      }
    }

    &.selected {
      border: 1px dashed $white;

      .ui-resizable-se, .ui-rotatable-handle, .design-your-room__box--remove {
        display: block !important;
      }

      .design-your-room__box--tools {
        display: flex;
      }
    }
  }

  &__tools {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: start;
    gap: 40px;

    padding: 20px 60px;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999999;

    overflow: scroll;
    width: 100%;

    @media (max-width: 1260px) {
      padding: 20px 30px;
    }

    span {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      gap: 15px;
      flex: 0 0 auto;

      &:before {
        content: "\002B";
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        background-color: $beige;
        color: $black;
        display: block;
        font-size: 1rem;
        line-height: 2rem;
        width: 2rem;
        text-align: center;
      }

      form {
        display: none !important;
      }
    }

    #download {
      &:before {
        content: "\f019";
      }
    }

    #new_photo {
      &:before {
        content: "\f030";
      }
    }

    #add_to_basket {
      display: none;
      opacity: 0;
      transition: all 200ms ease;

      &.visible {
        display: flex;
        opacity: 1;
      }

      &:before {
        content: "\f290";
      }
    }
  }
}