.you-might-like {
  padding: 80px 0 120px 0;
  overflow: hidden;
  position: relative;

  &__container {
    @extend .container;
    max-width: 100%;
    padding: 0;

    h2 {
      font-size: 1.45rem;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.24rem;
      margin: 0 0 80px 0;
    }
  }

  &__slider {
    display: flex;
    gap: 20px;
    position: relative;

    .slick-track {
      display: flex;
      align-items: center;
      min-width: 100%;
      height: 100%;
    }

    @include faded-edges();
  }

  &__slide {
    .product-card {
      margin: 0;
    }
  }

  &__pagination {
    display: none;
    gap: 10px;
    justify-content: center;

    @media (min-width: 1024px) {
      display: flex;
    }
  }

  &__pager {
    background: $beige;
    width: 21px;
    height: 2px;
    cursor: pointer;
    margin-top: 50px;

    &.active, &:hover {
      background: $dark-grey;
    }
  }

  &__button {
    text-align: center;

    .button {
      @extend .button--transparent;
      margin: 60px auto 0 auto;
    }
  }
}

.logged-in.page-id-12 .you-might-like {
  display: none;
}