.gift-card-field {
  @extend .column;
  gap: 5px;

  flex: 0 0 auto;

  @media (max-width: 1000px) {
    width: 100%;
  }

  label {
    font-size: 0.72rem;
  }

  &__container {
    @extend .input--text;
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
    width: 100%;

    input {
      @extend .input--text;
      font-size: 0.82rem !important;
      padding: 15px 20px !important;
      border: 0 !important;
      flex: 0 1 auto !important;
      width: 100% !important;
    }

    button {
      @extend .button;
      @extend .button--beige;
      background-color: transparent !important;
      font-size: 0.56rem !important;
      text-transform: uppercase !important;
      text-align: center !important;
      letter-spacing: 0.15rem !important;
      font-weight: normal !important;
      color: $black !important;
      text-decoration: none !important;
      font-family: $body;
      border-radius: 0 !important;
      margin: 5px !important;
      padding: 15px 20px !important;
      min-width: max-content !important;
      border: 1px solid $beige !important;
    }
  }
}

.gift-card-fields {
  flex: 1 0 100%;
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  p {
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 0;

    &:before, &:after {
      display: none !important;
    }

    input {
      @extend .input--text;
    }

    textarea {
      height: auto !important;
    }
  }
}