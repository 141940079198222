.pdf-viewer {
  &__container {
    @extend .container;
    text-align: center;
  }

  &__viewer {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  &__iframe {
    width: 100%;
    max-width: 795px;
    aspect-ratio: 1 / 1.755;
    border: none;
  }

  &__download-button {
    @extend .button;
    @extend .button--black;
    margin: 30px auto 0 auto;
  }
}