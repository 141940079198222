.notice {
  background-color: $light-beige;
  border: 0;
  margin-bottom: 60px;

  @media (max-width: 800px) {
    margin-bottom: 40px;
  }

  &__container {
    @extend .container;
    padding: 20px 30px !important;
    display: flex;
    align-items: center;
    text-align: center;
    align-content: center;
    justify-content: center;
    gap: 20px;

    a {
      color: $black;
    }

    ul, li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    @media (max-width: 800px) {
      font-size: 0.82rem;
      padding-top: 20px;
      padding-bottom: 20px;
      flex-direction: column;
      gap: 10px;
    }

    .restore-item {
      @extend .button;
      @extend .button--black;
    }
  }
}

.woocommerce-notices-wrapper {
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  padding: 0;
}

.woocommerce-message {
  background-color: $light-beige !important;
  border: 0 !important;

  &:before {
    color: darken($beige, 20%) !important;
  }
}