.woocommerce-tabs {
  .tabs {
    width: 100%;
    padding: 0 15px !important;
    margin-bottom: 90px !important;

    @media (max-width: 1200px) {
      margin-bottom: 0 !important;
    }

    @media (min-width: 1200px) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: stretch;
      align-content: stretch;
      gap: 40px;
    }

    @media (max-width: 800px) {
      margin-bottom: 25px !important;
    }

    li {
      @extend .transition;
      width: 100%;
      text-align: left;
      border: 0 !important;
      border-bottom: 1px solid $dark-grey !important;
      border-radius: 0 !important;
      padding: 0 !important;
      background: $white !important;
      align-content: center;
      margin: 0 !important;

      &:first-child {
        border-top: 1px solid $dark-grey !important;
        @media (min-width: 1200px) {
          border-top: 0 !important;
        }
      }

      @media (min-width: 1200px) {
        width: 260px;
        text-align: center;

      }

      &::before, &::after {
        display: none !important;
      }

      a {
        @extend .transition;
        font-size: 0.72rem;
        text-transform: uppercase;
        letter-spacing: 0.24rem;
        font-weight: normal !important;
        color: $dark-grey !important;
        width: 100%;
        padding: 30px 0 !important;
        text-align: left;
        position: relative;

        &:hover {
          color: $black !important;
        }

        &:after {
          content: '+';
          display: block;
          position: absolute;
          top: 20px;
          right: 0;
          bottom: 0;
          font-size: 1.25rem;
          color: $black;
          font-weight: bold;
        }

        @media (min-width: 1200px) {
          padding: 30px !important;
          text-align: center;


          &:after {
            display: none
          }
        }
      }

      &.active {
        border-bottom-color: $beige !important;

        a {
          color: $black !important;

          &:after {
            content: '-';
          }
        }
      }
    }

    &::before {
      display: none !important;
    }
  }

  .panel {
    @extend .container;
    max-width: 1000px;
    margin: 0 auto !important;
    padding: 90px 15px !important;

    @media (min-width: 1200px) {
      padding: 0 !important;
    }

    @media (max-width: 800px) {
      padding: 60px 15px !important;
    }

    > h2:first-child {
      display: none !important;
    }

    ul {
      @extend .list;
    }

    table {
      @extend .table;

      border-collapse: collapse !important;

      th {
        border-bottom: none !important;
        line-height: 1.7 !important;
        vertical-align: top !important;
        padding-top: 7px !important;
      }

      td {
        font-size: 0.92rem !important;
        border-bottom: none !important;
        line-height: 1.7 !important;

        p {
          padding: 0 !important;
        }
      }
    }
  }
}

.tabs--desktop {
  display: block;

  @media (max-width: 1200px) {
    display: none;
  }
}

.tabs--mobile {
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
}