.pre-header {
  background: $beige;
  z-index: 10000;
  position: sticky;
  top: 0;
  width: 100vw;
  overflow: hidden;

  &__container {
    @extend .container;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    gap: 20px;
    min-height: 40px;
    max-height: 40px;
  }

  &__links {
    font-size: 0.72rem;
    display: none;
    gap: 20px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    white-space: nowrap;

    @media (min-width: 1200px) {
      display: flex;
    }
  }

  &__link {
    color: $black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__announcements {
    font-size: 0.5rem;
    letter-spacing: 0.07rem;
    line-height: 0.8rem;
    text-transform: uppercase;
    width: 100%;
    height: 40px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    gap: 30px;

    @media (min-width: 1200px) {
      letter-spacing: 0.15rem;
    }
  }

  &__slider {
    width: 100%;
    max-width: 800px;
    text-align: center;

    .slick-track {
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: stretch;
      align-content: stretch;
    }

    .slick-list {
      height: 100% !important;
    }
  }

  &__arrow {
    height: 100%;
    width: 10px;
    cursor: pointer;
    position: absolute;
    top: 0;
    z-index: 99;
    bottom: 0;
    display: flex;

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }

    img {
      height: 18px !important;
      position: relative;
      top: 11px;
    }
  }

  &__announcement {
    width: 100% !important;
    padding: 0 20px !important;
    height: 100%;
    display: flex !important;
    text-align: center;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: auto 0;

    @media (min-width: 1200px) {
      padding: 0 !important;
    }

    a {
      color: $black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__announcement-text {
    width: 100%;
  }

  &__icons {
    display: none;
    gap: 20px;
    flex-wrap: nowrap;
    justify-content: flex-end;

    @media (min-width: 1200px) {
      display: flex;
    }
  }

  &__icon {
    width: 20px;
    height: auto;

    img {
      display: block;
      margin: 0;
    }
  }
}

.admin-bar .pre-header {
  top: 32px;

  @media (max-width: 782px) {
    top: 46px;
  }
}

.has-design-your-room .pre-header {
  position: relative;
  top: 0 !important;
}