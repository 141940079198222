.cart {
  &_item {
    &__desktop {
      display: table-row !important;

      .review-order__list {
        @media (max-width: 768px) {
          flex: 1;
        }

        ul {
          li {
            font-family: $body !important;
          }
        }
      }
    }

  }

  &__container {
    @extend .container;
    max-width: 1200px;
  }

  table {
    border: 0 !important;
    width: 100% !important;
    margin: 0 -1px 24px 0 !important;
    text-align: left !important;
    border-spacing: 0 !important;

    @media (max-width: 1000px) {
      border-collapse: collapse !important;
    }

    thead {
      @media (max-width: 1000px) {
        display: none;
      }
    }

    tbody {
      @media (max-width: 1000px) {
        border-top: 1px solid $beige;
      }
    }

    tr {
      position: relative;

      @media (max-width: 1000px) {
        border-bottom: 1px solid $light-grey;
      }

      .remove {
        position: absolute;
        margin-top: -20px;
        margin-left: min(calc(100vw - 70px - 60px), 1042px);
        font-size: 0.81rem;
        color: $dark-grey !important;
        width: auto;
        font-weight: normal;

        @media (max-width: 768px) {
          position: relative;
          bottom: unset;
          right: unset;
          text-align: right;
          padding: 0 20px 20px 20px;
          height: auto;
        }

        &:hover {
          background: transparent;
          color: $black !important;
        }
      }
    }

    th {
      font-size: 0.72rem !important;
      text-transform: uppercase;
      letter-spacing: 0.24rem;
      font-weight: normal !important;
      padding: 30px 20px !important;
    }

    th.subtotal {
      text-align: right !important;
    }

    th.product-quantity {
      text-align: center !important;
    }

    td {
      border-color: $beige !important;
      padding: 40px 20px !important;
      vertical-align: top !important;
      border-width: 1px 0 0 0 !important;
      border-style: solid !important;
      line-height: 1.5em !important;
      background-color: transparent !important;

      @media (max-width: 1000px) {
        border-color: transparent !important;
        border-width: 0 !important;
      }

      &:before {
        content: '' !important;

        @media (max-width: 1000px) {
          display: none !important;
        }
      }
    }

    .product {
      padding: 20px !important;

      @media (max-width: 768px) {
        padding: 20px 0 !important;
      }

      &__details {
        display: flex;
        gap: 35px;

        @media (max-width: 768px) {
          gap: 20px;
        }
      }

      &__image {
        a {
          height: 100% !important;
          display: block;
          line-height: 0 !important;
        }

        img {
          width: 150px !important;
          max-width: 150px !important;
          object-fit: cover;
          height: 150px !important;

          @media (max-width: 420px) {
            width: 120px !important;
          }
        }
      }

      &__title {
        font-family: $headings;
        font-size: 1rem;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 10px;

        @media (max-width: 768px) {
          justify-content: space-between;
          text-align: left;
          flex: 1;
          padding: 0;
        }

        &, a {
          color: $black;
          text-decoration: none;
        }

        p {
          font-family: $body;
          font-size: 0.82rem;
          margin: 0;
          text-align: left !important;
        }

        .product-quantity {
          font-family: $body !important;
          font-size: 0.82rem !important;
        }

        .wc-item-meta {
          padding-left: 0 !important;

          strong {
            font-size: 0.82rem !important;
            font-family: $body !important;
          }
        }
      }

      &_quantity {
        &:before {
          display: none !important;
        }
      }
    }

    td.price {
      @media (max-width: 768px) {
        display: none !important;
      }
    }

    td.price, td.subtotal {
      font-size: 1rem;
      font-style: italic;
    }

    td.product-quantity {
      text-align: center !important;

      @media (max-width: 768px) {
        padding: 0 !important;
      }
    }

    td.subtotal {
      text-align: right !important;

      @media (max-width: 768px) {
        position: absolute;
        bottom: 20px;
        right: 0;
        padding-top: 0 !important;
      }

      @media (max-width: 420px) {
        position: relative;
        bottom: 0;
        padding-bottom: 20px !important;
      }
    }

    .quantity {
      max-width: 100px;
      margin: 0 auto !important;

      @media (max-width: 1000px) {
        margin: 0 !important;
      }
    }

    .amount {
      font-style: italic !important;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 750px) {
      flex-direction: row;
    }
  }

  &__coupon {
    width: 100%;

    .coupon {
      @extend .input--text;
      padding: 0 !important;
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 100%;
      border: 1px solid $beige !important;

      @media (min-width: 750px) {
        max-width: 385px;
      }

      input {
        @extend .input--text;
        font-size: 0.82rem !important;
        padding: 15px 20px !important;
        border: 0 !important;
        width: 100% !important;
        text-transform: uppercase !important;
      }

      ::-webkit-input-placeholder {
        text-transform: none !important;
      }

      :-moz-placeholder {
        text-transform: none !important;
      }

      ::-moz-placeholder {
        text-transform: none !important;
      }

      :-ms-input-placeholder {
        text-transform: none !important;
      }

      ::placeholder {
        text-transform: none !important;
      }

      .button {
        @extend .button--beige;
        font-size: 0.56rem !important;
        text-transform: uppercase !important;
        text-align: center !important;
        letter-spacing: 0.15rem !important;
        font-weight: normal !important;
        text-decoration: none !important;
        font-family: $body;
        border-radius: 0;
        margin: 5px;
        padding: 15px 20px !important;
        min-width: 120px;
      }
    }

  }

  &__update {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .button {
      @extend .button--transparent;
      font-size: 0.56rem !important;
      text-transform: uppercase !important;
      text-align: center !important;
      letter-spacing: 0.15rem !important;
      font-weight: normal !important;
      text-decoration: none !important;
      font-family: $body;
      border-radius: 0 !important;
      margin: 5px;
      padding: 13px 20px !important;
      min-width: 180px;
      line-height: 1rem !important;
      width: 100%;

      @media (min-width: 768px) {
        width: auto;
      }

      &[disabled], &:disabled {
        @extend .button--transparent;

        color: $white !important;

        &:before {
          width: 100% !important;
        }

        &:after {
          width: 100% !important;
        }

        &:hover {
          color: $white !important;

          &:before, &:after {
            width: 100% !important;
          }
        }
      }
    }
  }

  .cart-totals {
    margin-top: 90px;

    @media (max-width: 1000px) {
      margin-top: 60px;
    }

    table {
      border-collapse: separate !important;

      th {
        padding: 30px 20px !important;

        @media (max-width: 1000px) {
          padding: 20px !important;
        }
      }

      td {
        padding: 30px 20px !important;

        @media (max-width: 1000px) {
          padding: 20px !important;
        }
      }
    }

    h2 {
      font-size: 1.45rem;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      margin-bottom: 30px;
    }

    tr {
      display: table-row !important;
    }

    th {
      letter-spacing: 0;
      text-transform: none;
      display: table-cell !important;
      font-size: 1rem;
    }

    td, th {
      background-color: $light-beige !important;
      padding: 30px 40px;
      border-color: $white !important;
      border-width: 1px 0 0 0 !important;
      border-style: solid !important;
    }

    td {
      text-align: right;
      font-style: italic;
      font-size: .72rem;
      display: table-cell !important;
      vertical-align: middle !important;
      padding: 30px 20px !important;

      @media (max-width: 1000px) {
        padding: 20px !important;
      }
    }

    /*
    tr:nth-child(2n) td {
      background-color: $light-beige !important;
    }
    */

    .order-total {
      th {
        font-size: 1rem !important;
        background-color: $beige !important;
      }

      td {
        font-size: 1rem !important;
        background-color: $beige !important;
      }

      &:nth-child(2n) {
        background-color: $beige !important;
      }
    }

    .shipping {

      th, td {
        padding-bottom: 0 !important;

        @media (max-width: 1000px) {
          padding: 20px !important;
        }
      }

      th {
        vertical-align: middle;

        span {
          color: $dark-grey;
        }

        img {
          width: 22px;
          height: auto;
          display: inline-block;
          margin: 0 0 0 5px;
          position: relative;
          top: 6px;
        }
      }
    }

    .shipping-calculator {
      th, td {
        border: 0 !important;
        padding-top: 0 !important;

        @media (max-width: 1000px) {
          padding: 0 20px 30px 20px !important
        }
      }

      &__form {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
        align-content: center;
        gap: 20px;
        margin-top: 20px;

        @media (max-width: 1000px) {
          margin-top: 0;
          flex-direction: column;
        }

        select, input, button {
          width: 100%;
        }

        select {
          background: $white;
        }

        input {
          @extend .input--text;
          font-size: .56rem !important;
          text-transform: uppercase !important;
          padding: 13px !important;
          letter-spacing: .15rem !important;
        }

        .button {
          @extend .button--transparent;
          font-size: 0.56rem !important;
          text-transform: uppercase !important;
          text-align: center !important;
          letter-spacing: 0.15rem !important;
          font-weight: normal !important;
          text-decoration: none !important;
          font-family: $body;
          border-radius: 0 !important;
          padding: 13px 20px !important;
          min-width: 180px;
          line-height: 1rem !important;
        }
      }
    }

    .order-total {
      td, th {
        background: $beige;
      }
    }
  }


  &__actions {
    @extend .column;

    flex-wrap: wrap;
    align-items: end;
    gap: 20px;

    margin-left: auto;
    width: max-content;

    @media (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      width: 50%;
      margin: 0 auto;
    }

    @media (max-width: 470px) {
      width: 100%;
    }

    > div, > a { /*All else right column*/
      width: max-content;

      @media (max-width: 470px) {
        width: 100%;
      }
    }

    /*Gift card*/
    #pwgc-redeem-error {
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      height: 20px;
    }

    #pwgc-redeem-gift-card-form {
      @extend .input--text;
      padding: 0 !important;
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 385px;
      position: relative;
      margin: calc(0.81rem + 15px) 0 0 0;
    }

    #pwgc-redeem-gift-card-container {
      width: 100%;
      display: flex;
      margin: 0;

      label {
        position: absolute;
        top: calc(-0.81rem - 15px);
        left: 0;
        font-size: 0.81rem;
      }

      input {
        @extend .input--text;
        font-size: 0.82rem !important;
        padding: 15px 20px;
        border: 0;
        width: 100% !important;
        display: flex !important;
      }

      .button {
        @extend .button--beige;
        font-size: 0.56rem !important;
        text-transform: uppercase !important;
        text-align: center !important;
        letter-spacing: 0.15rem !important;
        font-weight: normal !important;
        text-decoration: none !important;
        font-family: $body;
        border-radius: 0 !important;
        margin: 5px !important;
        padding: 15px 20px !important;
        min-width: 180px;
        display: flex !important;
      }
    }

    .ppcp-messages {
      order: 3;
      width: 100%;

      span {
        display: block;
        max-width: 380px;
        width: 100%;
        margin-left: auto;
        position: relative;

        &:after {
          content: '— or —';
          display: block;
          position: absolute;
          top: -45px;
          left: 0;
          width: 100%;
          text-transform: lowercase;
          font-size: 0.81rem;
          font-weight: normal;
          text-align: center;
          letter-spacing: 0;
        }
      }
    }

    .ppc-button-wrapper {
      order: 4;
      width: 100%;

      #ppc-button-ppcp-gateway {
        max-width: 380px;
        width: 100%;
        margin-left: auto;

        @media (max-width: 1000px) {
          max-width: none;
        }
      }
    }

    #wc-stripe-express-checkout-element {
      width: 100% !important;
      margin: 0 !important;
    }

    /*Checkout button*/
    .checkout-button {
      @extend .button;
      @extend .button--beige;
      font-size: 0.56rem !important;
      text-transform: uppercase !important;
      text-align: center !important;
      letter-spacing: 0.15rem !important;
      font-weight: normal !important;
      text-decoration: none !important;
      font-family: $body;
      border-radius: 0 !important;
      padding: 20px 30px !important;
      min-width: 180px;
      width: 380px;
      margin-left: 0 !important;
      margin-bottom: 0 !important;
      position: relative;
      line-height: 1 !important;

      @media (max-width: 1000px) {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  &__empty {
    @extend .row-space-between;
    justify-content: center;
  }

}

body.trade .cart__coupon .coupon {
  visibility: hidden !important;
}