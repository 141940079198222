.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $black;
  opacity: 0.6;
  z-index: 9999 !important;
}

.ui-dialog {
  padding: 0;
  background: $white;
  z-index: 99999;
  text-align: left;
  border: 0;

  /*
  @media (max-width: 700px) {
    padding: 20px;
  }
  */

  h1, h2, h3 {
    margin: 0 0 20px 0;
  }
}

.dialog {
  &__titlebar {
    cursor: move;
    background: $beige;
    padding: 5px 20px;
    text-align: right;

    button {
      padding: 0;
      margin: 0;
      display: inline-block;
      border: 0;
      background-image: url('../images/nav-close.svg');
      background-color: transparent;
      position: relative;
      top: -8px;
      width: 20px;
      height: 20px;
      font-size: 0;
      cursor: pointer;

      &:hover {

      }
    }
  }

  &__text {
    height: auto !important;
    max-height: 55vh !important;
    overflow-y: auto;
    border-bottom: 1px solid $light-beige;
    padding: 15px 30px;
    font-size: 0.82rem !important;

    strong {
      font-weight: 500;
    }
  }

  &__buttons {
    padding: 15px 30px;
  }

  &__buttons-inner {
    button, a {
      display: inline-block;
      margin: 0 10px 0 0;
      border: 1px solid $black;

      @extend .button;
    }
  }
}