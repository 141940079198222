.product-rating {
    position: relative;
    cursor: default;
    user-select: none;
    width: min-content;

    * {
        font-family: WooCommerce;
        letter-spacing: .1rem;
        font-size: .82rem;
        line-height: 1;
    }

    &__base {
        color: $light-grey;
        position: relative;
        z-index: 1;
    }

    &__fill {
        color: $beige;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 2;
    }
}