.post-archive {
  padding: 30px 0;

  @media (min-width: 750px) {
    padding: 130px 0 100px 0;
  }

  &__container {
    @extend .container;
  }

  &__title {
    text-align: center;
    margin-bottom: 80px;
  }

  &__query {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    border-bottom: 1px solid $light-grey;
    padding-bottom: 40px;
    margin-bottom: 60px;

    @media (min-width: 750px) {
      flex-direction: row;
    }
  }

  &__categories {
    position: relative;
    margin: 0 0 15px 0;

    select {
      background-color: transparent;
      border: 1px solid $light-grey;
      padding: 0 150px 0 20px;
      line-height: 60px;
    }

    .custom-select {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 400;

      div {
        position: absolute !important;
      }
    }

    @media (min-width: 750px) {
      margin: 0;
    }
  }

  &__search {
    font-size: 0;
    border: 2px solid $beige;

    input {
      all: unset;
      font-size: 24px;
      line-height: 60px;
      padding: 0 0 0 20px;
    }

    button {
      all: unset;
      cursor: pointer;
      padding: 0 20px;
      height: 100%;

      i {
        font-size: 24px;
        color: $dark-grey;
      }
    }
  }


  &__posts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: start;

    @media (max-width: 1000px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 625px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 750px) {
      grid-template-columns: 1fr;
    }

    .post-teaser {
      width: 100% !important;
      /*max-width: 312px !important;*/

      @media (max-width: 500px) {
        max-width: none !important;
      }
    }
  }
}

#post-search-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}