.list {
  list-style-type: none; /* Remove default bullets */
  padding-left: 0; /* Remove left padding */
  margin: 0; /* Remove margin */

  li {
    position: relative;
    padding-left: 20px; /* Add space for custom bullet */

    &:before {
      content: "•"; /* Insert bullet symbol */
      position: absolute;
      left: 0; /* Position bullet on the left */
      color: $beige; /* Change bullet color */
    }
  }
}