// SCSS for delivery-header section
.delivery-header {
    padding: 80px 0;

    &__container {
        @extend .container;
        padding: 0 100px;
    }

    h1 {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    h2 {
        text-align: center;
        text-transform: uppercase;
    }

    p {
        text-align: center;
    }

    @media (max-width: 1200px) {
        &__container {
            padding: 0 50px;
        }
    }

    @media (max-width: 800px) {
        padding: 30px 0;

        &__container {
            padding: 0 15px;
        }
    }
}