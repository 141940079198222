.category-blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  gap: 0;

  &__item {
    aspect-ratio: 1 / 1;
    width: 100%;
    overflow: hidden;
    display: flex;
    position: relative;
    color: $white;

    &:after {
      /*gradient*/
      background: $black;
      background: linear-gradient(0deg, rgba($black, 1) 0%, rgba($black, 0) 100%);
      opacity: 0.8;
      content: '';
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 25%;
      z-index: 1;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      position: relative;
      z-index: 0;
    }

    @media (min-width: 800px) {
      width: 50%;
    }
  }

  &__text {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    padding: 70px;
    width: 100%;
    z-index: 2;

    h2 {
      font-size: 1.45rem;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
    }

    strong {
      font-size: 0.5rem;
      letter-spacing: 0.07rem;
      line-height: 0.8rem;
      text-transform: uppercase;
    }
  }
}