.ur-frontend-form {
  @extend .container;

  border: 0 !important;
  padding: 60px 15px !important;
  margin-bottom: 0 !important;

  @media (max-width: 600px) {
    padding: 40px 15px !important;
  }

  h1 {
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;

    @media (max-width: 400px) {
      font-size: 10vw;
    }
  }

  form {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
  }

  .ur-form-row {
    margin-bottom: 0 !important;

    .ur-form-grid {
      padding: 0 !important;
      display: flex !important;
      flex-direction: column !important;
      gap: 12px !important;
    }
  }

  .ur-field-item {
    margin-bottom: 0 !important;

    label {
      font-size: 1rem !important;
      line-height: 2rem !important;
      font-weight: 500 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      .required {
        color: $beige !important;
      }
    }

    input {
      @extend .input--text;

      color: $black !important;
      border: 1px solid $beige !important;
      padding: 15px 25px !important;
      line-height: 1.25rem !important;
      margin-bottom: 0 !important;
      min-height: auto !important;
    }
  }

  .ur-button-container {
    margin-top: 0 !important;
    padding: 0 !important;

    button {
      @extend .button;
      @extend .button--transparent;

      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 100% !important;
    }
  }
}

#ur_register_pre_form_message {
  margin: 0 !important;
  padding: 60px 15px !important;
  text-align: center !important;

  @media (max-width: 600px) {
    padding: 40px 15px !important;
  }
}


.user-registration__header {
  margin-bottom: 60px;

  p {
    text-align: center;
  }

  a {
    color: $black;
  }

}

.ur-frontend-form form {
  max-width: 800px !important;
  margin: 0 auto !important;
}

.user-registration-message {
  background-color: beige !important;
  border: 0;
  display: flex;
  font-size: 0.72rem;
  color: black;
  margin: 0 !important;
  padding: 30px 45px !important;
  text-align: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}


.user-registration-error {
  background-color: beige !important;
  border: 0;
  display: flex;
  font-size: 0.72rem;
  color: black !important;
  margin: 0 !important;
  padding: 15px !important;
  text-align: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}