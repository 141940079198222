.post-header {
    width: 100vw;
    max-width: 100%;
    height: 560px;

    &__container {
        @extend .container;
        height: 100%;

        @media (max-width: 1200px) {
            padding: 0;
        }

        &__background {
            height: 100%;
            position: relative;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;

            * {
                color: $white;
                text-align: center;
                z-index: 5;
            }

            p {
                margin: 0;
            }

            h1 {
                max-width: 700px;
                width: 100%;
                margin: 0 auto 50px;

                @media (max-width: 1200px) {
                    padding: 0 15px;
                }
            }

            &:before {
                content: '';
                width: 100%;
                height: 100%;
                display: block;
                background: rgb(0,0,0);
                background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
                position: absolute;
            }
        }
    }
}

.post-date {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 1;
    margin-bottom: 30px !important;
}

.post-author {
    font-size: 14px;
    font-style: italic;

    a {
        color: $beige;
        text-decoration: none;
    }
}

.post-content {
    width: 100vw;
    max-width: 100%;

    &__container {
        max-width: 900px;
        margin: 0 auto;
        padding: 100px 50px;

        @media (max-width: 800px) {
            padding: 40px 15px;
        }

        p {
            a {
                color: $beige;
                text-decoration: none;
            }
        }

        h1, h2, h3, h4, h5 {
            text-align: left !important;
            padding: 0 !important;

            a {
                color: $beige !important;
                text-decoration: none !important;
            }
        }

        h1:not(:first-child), 
        h2:not(:first-child), 
        h3:not(:first-child) {
            margin-top: 50px;
        }

        h4:not(:first-child),
        h5:not(:first-child) {
            margin-top: 30px;
        }

        & > p:first-child {
            margin-top: 0;
        }

        & > p:last-of-type:after {
            content: "\25a0";
            display: inline;
            color: $dark-grey;
            margin-left: 5px;
            vertical-align: 2px;
        }

        ul {
            list-style-type: none;

            li {
                position: relative;

                &:before {
                    content: "•";
                    position: absolute;
                    left: -1rem;
                    color: $beige;
                }

                a {
                    color: $beige !important;
                    text-decoration: none !important;
                }
            }
        }
    }
}

.post-related {
    &__container {
        @extend .container;

        &:before {
            content: "";
            display: block;
            background-color: $light-grey;
            height: 1px;
            width: 100%;

            @media (max-width: 1200px) {
                display: none;
            }
        }
    }

    &__heading {
        text-align: center;
        text-transform: uppercase;
        margin: 0;
        padding: 100px 0;

        @media (max-width: 800px) {
            padding: 50px 0;
        }
    }

    &__list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;

        .post-teaser {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}