.featured-in {
  padding: 30px 0;

  &__container {
    @extend .container;

    h1 {
      font-size: 1.18rem;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.24rem;
      margin: 0 0 80px 0;

      @media (min-width: 880px) {
        font-size: 1.45rem;
      }
    }
  }

  &__slider {
    .slick-track {
      display: flex;
      align-items: center;
      min-width: 100%;
      flex-wrap: nowrap;
    }
  }

  &__slide {
    width: 300px;
    height: 50px;
    flex-wrap: nowrap;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: stretch;
    align-content: center;

    img {
      width: 100%;
      max-width: 100px;
      height: auto;
      margin: auto;
    }
  }
}