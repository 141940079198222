.wlr {
  &-myaccount-page {
    padding: 0 !important;
    gap: 40px !important;
  }

  &-user-details {
    margin: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: start !important;
    gap: 40px !important;
  }

  &-heading {
    margin-bottom: 0 !important;
    border-left: 0 !important;
    color: $black !important;
    font-size: 1.45rem !important;
    font-family: $headings !important;
    padding-left: 0 !important;
    text-transform: none !important;
    margin-left: -3px !important;
    font-weight: 400 !important;

    &-container {
      max-width: none !important;
      justify-content: start !important;
    }
  }

  &-points-container {
    margin-bottom: 0 !important;

    #wlr-points {
      gap: 40px !important;
      border-radius: 0 !important;

      @media (max-width: 1000px) {
        width: 100% !important;
        flex-direction: column !important;
      }

      #wlr-available-points,
      #wlr-redeemed-points,
      #wlr-used-rewards {
        border-color: $dark-grey !important;
        gap: 20px !important;
        border-radius: 0 !important;
        padding: 15px !important;
        position: relative !important;
        justify-content: center !important;

        .wlr-icon {
          display: block !important;
          width: 100% !important;
          text-align: center !important;
          color: $beige !important;
        }

        #wlr_currency_list {
          @extend .button;
          @extend .button--transparent;
          background: $white !important;
          width: 60px !important;
          text-align: center !important;
          padding: 4px 6px !important;

          &:hover {
            @extend .button--black;
          }
        }

        span {
          font-family: $headings !important;
          font-size: 1rem !important;
          line-height: 1 !important;
          order: 1;
        }

        & > div:last-child {
          text-align: center !important;
          display: flex !important;
          flex-direction: column !important;
          gap: 5px !important;

          & > div:first-of-type {
            order: 0;
            font-size: 1rem !important;
            line-height: 1 !important;
          }
        }

        #wlr-available-point-value + .wlr-text-color {
          display: none !important;
        }
      }

      #wlr-used-rewards > div:first-child {
        justify-content: center !important;
        gap: 15px !important;

        & > div:last-child {
          position: absolute !important;
          top: 15px;
          right: 15px;
          display: flex !important;
          flex-direction: column !important;
          justify-content: start !important;
        }
      }

      #wlr-used-rewards > div:last-child > div:last-child {
        order: 2 !important;

        p {
          font-size: 0.63rem !important;
          line-height: 1.25 !important;
        }
      }
    }
  }

  &-reward-type-name {
    width: 100% !important;
    float: none !important;
  }

  &-theme-color-apply {
    color: $beige !important;
  }

  &-your-reward {
    display: flex !important;
    flex-direction: column !important;
    justify-content: start !important;
    gap: 40px !important;
    margin: 0 !important;
  }


  &-my-rewards-sections {
    gap: 40px !important;
  }

  &-user-reward-titles {
    border-bottom: 0 !important;
    justify-content: center !important;
    gap: 40px !important;

    @media (max-width: 600px) {
      gap: 0 !important;
    }
  }

  &-my-rewards-title {
    border-bottom: 1px solid $dark-grey !important;
    padding: 0 !important;
    position: relative !important;

    @media (max-width: 600px) {
      width: 100% !important;
    }

    &:first-child {
      @media (max-width: 600px) {
        border-top: 1px solid $dark-grey !important;
      }
    }

    &:after {
      content: "+";
      display: none;
      position: absolute;
      right: 0;
      top: 20px;
      font-size: 1.25rem;
      color: $black;
      font-weight: 700;

      @media (max-width: 600px) {
        display: block;
      }
    }

    &.active {
      border-bottom-color: $beige !important;

      &:after {
        content: "-";
      }

      h4 {
        color: $black !important;
      }
    }

    * {
      color: $black !important;
    }

    i {
      display: none !important;
    }

    h4 {
      font-family: $body !important;
      font-size: .72rem !important;
      letter-spacing: .24rem !important;
      font-weight: 400 !important;
      padding: 30px !important;
      color: $dark-grey !important;
      text-transform: uppercase !important;
      line-height: 1.7 !important;
      text-align: center !important;

      @media (max-width: 600px) {
        width: 100% !important;
        padding: 30px 0 !important;
        text-align: left !important;
      }
    }
  }

  &-transaction-blog {
    @extend .column;

    gap: 40px !important;
    margin: 0 !important;

    .wlr-table {
      border-collapse: separate;
      border-spacing: 0;

      &-header {
        border-radius: 0 !important;
        outline: 0 !important;

        th {
          background-color: transparent !important;
          text-transform: uppercase !important;
          font-weight: normal !important;
          letter-spacing: 0.1rem !important;
        }
      }

      .wlr-transaction-container {
        tr {
          td {
            border-top: 1px solid $light-beige !important;
            border-bottom: 0 !important;

            &:nth-child(4) {
              font-size: 1rem !important;
            }

            &:last-child {
              text-align: center !important
            }

            a {
              font-size: 1rem !important;
              text-decoration: none !important;
            }
          }
        }
      }
    }
  }

  &-earning-options {
    @extend .column;

    gap: 40px !important;
    margin: 0 !important;

    .wlr-campaign-container {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
      row-gap: 24px !important;
      column-gap: 12px !important;
    }
  }

  &-earning-option {
    border-radius: 0 !important;
    border: 1px solid $dark-grey !important;
    padding: 15px !important;
    width: 30% !important;

    @media (max-width: 1000px) {
      width: 100% !important;
    }

    .wlr-card-container {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      text-align: center !important;

      .wlr-icon {
        box-shadow: none !important;
        font-size: 52px !important;
        padding: 0 !important;
        margin: 0 !important;
        border-radius: 0 !important;
      }

      .wlr-name {
        line-height: 1 !important;

        p {
          font-size: 1rem !important;
          margin: 0 !important;
        }

        & + div {
          display: none !important;
        }
      }
    }
  }

  &-user-reward-contents {
    .wlr-rewards-content,
    .wlr-norecords-container {
      display: flex !important;
      flex-direction: column !important;
      justify-content: start !important;
      gap: 15px !important;
      padding: 0 !important;
    }

    i {
      color: $beige !important;
    }

    p {
      font-size: 1rem !important;
    }

    h4 {
      font-size: 1rem !important;
      line-height: 1.25 !important;
      font-weight: 400 !important;
    }
  }

  &-customer-reward {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;

    .wlr-reward-card {
      padding: 15px !important;
    }
  }

  &-reward-card {
    border: 1px solid $dark-grey !important;
    border-radius: 0 !important;
    padding: 15px !important;
    width: 30% !important;
    min-width: auto !important;
    min-height: auto !important;

    @media (max-width: 1000px) {
      width: 100% !important;
    }

    &:hover {
      box-shadow: none !important;
    }

    & > div {
      margin-right: 0 !important;
    }

    .wlr-reward-type-name {
      font-family: $headings !important;
      line-height: 1 !important;
      font-weight: 400 !important;
      border: 0 !important;
      border-radius: 0 !important;
      padding: 0 !important;

      .amount {
        position: relative !important;

        &:before {
          content: " ";
        }

        bdi {
          font-family: $body !important;
          font-style: italic !important;
        }
      }
    }

    .wlr-card-container {
      margin-top: 0 !important;
    }

    .wlr-card-inner-container {
      min-height: auto !important;
      margin: 0 0 28px !important;
    }

    .wlr-card-icon-container {
      margin: 0 !important;
    }

    .wlr-card-icon {
      font-size: 0 !important;
      text-align: center !important;

      i {
        font-size: 52px !important;
      }
    }

    .wlr-name {
      text-align: center !important;
    }

    .wlr-description {
      font-size: 0.63rem !important;
      line-height: 1.25 !important;
      text-align: center !important;
    }

    .wlr-button-reward {
      @extend .button;
      @extend .button--beige;

      font-size: .56rem !important;
      width: 100% !important;
      border-radius: 0 !important;

      span {
        color: $black !important;
      }

      &:hover, &:focus {
        @extend .button--beige--active;
      }
    }
  }

  &-enable-email-sent-blog {
    display: flex;
    flex-direction: column !important;
    justify-content: start !important;
    gap: 20px !important;
    margin: 0 !important;
  }

  &-sent-email {
    justify-content: start !important;
  }

  &-message-info {
    max-width: 1140px;
    margin: 0 auto 40px !important;
    border: 0 !important;
    border-radius: 0 !important;
    color: $black !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    background-color: $light-beige !important;
    padding: 20px 30px !important;

    @media (max-width: 1170px) {
      margin: 0 15px 40px !important;
    }

    p {
      @extend .row-space-between;

      width: 100% !important;
      align-items: center !important;
      position: relative !important;
    }

    #wlr-reward-link {
      @extend .button;
      @extend .button--beige;
    }
  }

  &-dot-pulse {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
}

.ajs {
  &-model {
    transform: none !important;
  }

  &-dialog {
    animation: none !important;
  }

  &-footer {
    margin: 0 !important;
    padding: 0 0 24px !important;
  }

  &-button {
    @extend .button;
    @extend .button--black;

    margin: 0 !important;
  }
}

.wlr_block_points_rewards_earn_points {
  margin: 0 !important;
}

.dashboard__content .wlr-myaccount-page .wlr-earning-options .wlr-card .wlr-card-container {
  margin-top: 0 !important;
}