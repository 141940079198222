.mobile-header {
  @extend .transition--slow;
  background: $white;
  border-bottom: 1px solid $light-beige;
  display: block;
  position: fixed;
  top: 40px;
  width: 100vw;
  z-index: 9999;

  &--transparent {
    background: transparent;
    border-bottom: 1px solid rgba($beige, 0.3);
    color: $white;

    .mobile-header__logo {
      svg, img {
        &, path {
          filter: brightness(100);
        }
      }
    }

    .header__icon {
      svg, img {
        &, path, rect {
          filter: brightness(100);
        }
      }
    }

    .header__item {
      &, path {
        color: $white;
      }
    }
  }

  @media (min-width: 1200px) {
    display: none;
  }

  &__container {
    @extend .container;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;
    gap: 20px;
    min-height: 80px;
    position: relative;
    transition: all 300ms ease;

    @media (max-width: 400px) {
      gap: 10px;
    }
  }

  &__icons {
    display: flex;
    width: fit-content;
    gap: 20px;
    align-items: center;

    @media (max-width: 400px) {
      gap: 10px;
    }
  }

  &__logo {
    @extend .transition;

    width: 126px;
    min-width: 126px;
    display: flex;
    align-items: center;

    @media (max-width: 400px) {
      width: 100px;
      min-width: 100px;
    }

    a {
      width: 100%;
      height: auto;
      display: flex;
    }

    img {
      display: inline-block;
      margin: 0;
      width: 100%;
    }
  }

  &__dropdown {
    position: absolute;
    width: 100%;
    display: none;
    background: $white;
    border-top: 1px solid $light-beige;
    height: auto !important;
    max-height: 100vh;
    @extend %clearfix;

    .mobile-header__container {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      height: fit-content;
      gap: 0;
      padding: 0;
    }
  }

  &__subnav {
    @extend %clearfix;
    display: block;
    width: 100%;
    text-align: center;
    border-top: 0;
    position: static;
    padding: 0;
    margin: 0 auto;

    .search {
      margin: auto 15px
    }

    .search__input {
      width: 100%;
      margin: 15px auto;
    }
  }

  .header__icon {
    &.blank {
      width: 20px;
    }

    .mobile-header__nav-icon {
      &--close {
        display: none !important;
      }
    }

    &.active {
      .mobile-header__nav-icon {
        &--hamburger {
          display: none !important;
        }

        &--close {
          display: block !important;
        }
      }
    }
  }

  &__scroll {
    .mobile-header__container {
      min-height: 60px;
    }
  }
}

.admin-bar .mobile-header {
  top: 72px;

  @media (max-width: 782px) {
    top: 86px;
  }
}

.has-header--transparent .mobile-header__scroll {
  background: $white;
  color: $black;
  border-bottom: 1px solid $light-beige;

  .mobile-header__logo,.header__icon {
    img {
      filter: brightness(1);
    }
  }

  .header__item {
      color: $black;
  }
}

.has-design-your-room .mobile-header {
  position: absolute !important;
  top: 40px !important;
}