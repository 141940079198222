// SCSS for square section
.square {
    width: 100vw;
    max-width: 100%;

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: nowrap;

        &.even {
            flex-direction: row-reverse;
        }

        &.reverse {
            flex-direction: row-reverse;
        }

        &.reverse.even {
            flex-direction: row;
        }
    }

    &__picture {
        flex: 0 0 50%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        background-color: $beige;
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 120px;

        * {
            text-align: center;
        }

        .button {
            background: transparent;
            border: 2px solid $black;
            max-width: max-content;
            margin: 38px auto 0 auto;
        }
    }

    &__text {
        p a {
            color: $black;
        }
    }

    @media (max-width: 1200px) {
        &__content {
            padding: 120px 50px;
        }
    }

    @media (max-width: 1024px) {
        &__row {
            flex-direction: column !important;
        }

        &__picture {
            max-height: 50vh;
        }
        /*
        &__content {
            padding: 50px;
        }
        */
    }

    @media (max-width: 800px) {
        &__content {
            padding: 40px 25px 70px 25px;

            .button {
                margin-top: 20px;
            }
        }
    }
}