.review-order {
  margin-top: 44px;

  @media (max-width: 800px){
    margin-top: 20px;
  }

  &__item {
    display: flex;
    gap: 35px;
    border-bottom: 1px solid $dark-grey;
    justify-content: space-between;
    padding: 20px 0;
    align-items: stretch;
    align-content: stretch;
  }

  &__thumbnail {
    width: 100%;
    max-width: 100px;
    align-items: flex-start;
  }

  &__details {
    width: 100%;
    position: relative;
    padding: 0 20px 0 0;
  }

  &__title {
    position: relative;
    top: -4px;

    a {
      display: flex;
      line-height: 1rem;
      text-align: left !important;
    }

    &, a {
      font-size: 1rem;
      text-decoration: none;
      color: $black;
      font-family: $headings;
    }
  }

  &__price {
    font-style: italic;
    position: absolute;
    bottom: -4px;
    right: 20px;
    text-align: right;
    font-size: 0.9rem;
  }

  &__list {
    ul {
      @extend .list;
      font-size: 0.82rem;
    }
  }

  &__row {
    background: $light-beige;
    border-bottom: 1px solid $white;
    display: flex;
    gap: 20px;

    @media (max-width: 450px) {
      gap: 0;
    }

    &--total {
      background: $beige;
    }

    &--coupon {
      align-items: center;

      strong {
        font-weight: normal;
        display: flex;
        flex-direction: column;

        span {
          text-transform: uppercase;
          font-weight: bold;
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 10px;

          .js-checkout-remove-coupon {
            color: $black !important;
            text-decoration: none !important;
          }
        }

        em {
          font-weight: normal;
          font-size: 0.56rem;

          span {
            display: inline;
          }
        }
      }

      > span {
        display: flex;
        flex-wrap: nowrap;
      }
    }

    &--shipping {
      @media (max-width: 450px) {
        flex-direction: column;
      }

      &.single-method {
        @media (max-width: 450px) {
          flex-direction: row;
        }
      }

      strong {
        flex: 0 1;

        @media (max-width: 450px) {
          padding: 20px 20px 0 20px;
        }
      }
    }

    strong {
      font-size: 0.82rem;
      width: 100%;
      padding: 20px;
      font-weight: 500;
    }

    > span {
      text-align: right;
      font-style: italic;
      font-size: 0.9rem;
      padding: 20px;
    }
  }

  &__shipping-methods {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex: 1 1 100%;
    padding: 20px;

    @media (max-width: 450px) {
      padding: 0 20px 20px 20px;
    }

    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      li {
        display: inherit;
        align-items: start;
        margin: 0 !important;

        input {
          -webkit-appearance: none !important;
          -moz-appearance: none !important;
          appearance: none !important;
          outline: none !important;

          width: 20px;
          height: 20px;
          flex: 0 0 auto;

          border: 2px solid rgba(0, 0, 0, 0.5) !important;
          border-radius: 0 !important;
          margin: 0 10px 0 0 !important;
          padding: 0 !important;
          opacity: 0.5 !important;
          position: relative;
          top: 6.5px;

          cursor: pointer;

          &:before {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;

            width: 12px;
            height: 12px;

            background-color: $black;
            display: block;
            opacity: 0;

            transition: all 300ms;
          }

          & ~ * {
            opacity: 0.5;
          }

          &[type="hidden"] {
            & ~ .shipping-method {
              cursor: unset !important;

              .shipping-method__label {
                display: none !important;
              }
            }
          }

          &[checked="checked"], &[type="hidden"] {
            opacity: 1 !important;

            &:before {
              opacity: 1 !important;
            }

            & ~ * {
              opacity: 1 !important;
            }
          }

          &:hover {
            &:before {
              opacity: 0.5 !important;
            }
          }
        }

        .shipping-method {
          display: flex !important;
          flex-direction: row;
          justify-content: space-between;
          flex: 1;
          cursor: pointer;

          &__label {
            font-size: .9rem !important;
          }

          &__cost {
            flex: 1;
            display: flex;

            .amount {
              flex: 1;
              font-size: .9rem !important;
              font-style: italic !important;
              font-weight: 400 !important;
              text-align: right !important;
            }
          }
        }
      }
    }
  }
}

.woocommerce-order {
  &-overview {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 20px;

    padding: 0;
    font-size: 1rem;
    text-align: center;

    &:before {
      display: none !important;
    }

    li {
      border: none !important;
      padding-right: 0 !important;
      margin-right: 0 !important;

      font-size: 1rem !important;

      strong {
        display: inline !important;
        font-size: 1rem !important;
      }
    }
  }
}