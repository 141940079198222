h1, h2, h3, h4, h5, .h3 {
  font-family: $headings;
  font-weight: 400;
  margin: 0 0 15px 0;
  letter-spacing: 0.1rem;
  line-height: 1.12;
}

h1, .h1 {
  font-size: 2.27rem;
}

h2, .h2 {
  font-size: 1.45rem
}

h3, .h3{

}