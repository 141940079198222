%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Define the 'faded-edges' mixin
@mixin faded-edges($width: 40px) {
  // Apply to both &:before and &:after pseudo-elements
  &:before, &:after {
    content: '';
    width: $width;
    height: 100%;
    background: $white; // Ensure $white is defined in your variables
    opacity: 0.5;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 99;
  }

  // Position the &:before pseudo-element on the left
  &:before {
    left: 0;
  }

  // Position the &:after pseudo-element on the right
  &:after {
    right: 0;
  }
}

.outline-button {
  background-color: #fff !important;
  border: 1px solid #0a0a0a !important;
  color: #0a0a0a !important;
  font-family: "Times New Roman",Times,serif !important;
  border-radius: 0 !important;
  font-size: .59rem !important;
  padding: 15px !important;

  &.alt {
    background-color: $black !important;
    color: $white !important;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.row-space-between {
  @extend .row;
  justify-content: space-between;
}

.item-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.transition {
  transition-property: all !important;
  transition-duration: 300ms !important;
  transition-timing-function: ease !important;

  &--slow {
    transition-duration: 500ms !important;
  }
}

.selection {
  background: darken($beige, 10%);
  color: $black;
}

::-moz-selection {
  @extend .selection;
}

::selection {
  @extend .selection;
}