.button {
  @extend .transition--slow;
  background: transparent !important;
  font-size: 0.56rem !important;
  text-transform: uppercase !important;
  text-align: center;
  letter-spacing: 0.15rem !important;
  font-weight: normal !important;
  color: $black !important;
  text-decoration: none !important;
  padding: 15px 45px !important;
  display: inline-block;
  cursor: pointer !important;
  font-family: $body;
  border: 1px solid $black !important;
  border-radius: 0 !important;
  position: relative !important;
  z-index: 2;
  opacity: 1 !important;

  &:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $white !important;
    z-index: -2;
  }

  &:before {
    @extend .transition--slow;

    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: $black !important;
    z-index: -1;
  }

  &:hover {
    color: $white !important;
    border: 1px solid $black !important;

    &:before {
      width: 100%;
    }
  }

  &[disabled], &:disabled {
    color: $white !important;
    border: 1px solid $black !important;
    opacity: 0.5 !important;

    &:before {
      background: $black !important;
      width: 100% !important;
    }

    &:after {
      background: $black !important;
    }
  }

  &--transparent {
    color: $black !important;
    border: 1px solid $black !important;

    &:after {
      background: transparent !important;
    }

    &:before{
      background: $black !important;
    }

    &:hover {
      color: $white !important;
    }
  }

  &--black {
    color: $white !important;
    border: 1px solid $black !important;

    &:hover {
      color: $black !important;
      border: 1px solid $black !important;
    }

    &:after {
      background: $black !important;
    }

    &:before {
      background: $white !important;
    }
  }

  &--beige {
    color: $black !important;
    border: 1px solid $beige !important;

    &--active {
      border: 1px solid darken($beige, 5%) !important;
    }

    &:after {
      background: $beige !important;
    }

    &:before {
      background: darken($beige, 5%) !important;
    }

    &:hover {
      color: $black !important;
      border: 1px solid darken($beige, 5%) !important;
    }
  }

  &--product {
    color: $white !important;
    border: 1px solid rgba($black, 0.66) !important;

    &:after {
      background: rgba($black, 0.66) !important;
    }

    &:before {
      background: $black !important;
    }

    &:hover {
      border: 1px solid $black !important;
    }
  }
}