.usps {
  padding: 100px 0;

  &__container {
    @extend .container;
    display: block;
    gap: 24px;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;

    @media (min-width: 880px) {
      display: flex;
    }
  }

  &__item {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 5px;
    margin: 0 0 20px 0;

    @media (min-width: 880px) {
      max-width: 280px;
      margin: 0;
    }

    img {
      min-height: 32px;
      width: auto;
      max-height: 50px;
    }

    h2 {
      font-size: 1rem;
      font-family: $body;
      margin: 20px 0 0 0;
    }

    p {
      font-size: 0.82rem;
      margin: 0;
    }

    a {
      color: $black !important;
    }
  }
}