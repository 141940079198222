.wc-block, .wc-blocks {
  &-checkout {
    &__guest-checkout-notice {
      font-style: italic !important;
      width: 100% !important;
      margin: 0 !important;
    }

    &__create-account {
      width: 100% !important;
      margin-top: 0 !important;
    }

    &__login-prompt {
      margin-top: 0 !important;
      line-height: 30px !important;
    }

    &__shipping-option {
      &:after {
        display: none !important;
      }
    }

    &__shipping-fields, &__order-notes {
      @media (max-width: 600px) {
        padding-top: 0 !important;
      }
    }

    &__order-notes {
      & + .css-0 {
        .wp-block-woocommerce-checkout-order-summary-block {
          display: none !important;
        }
      }
    }
  }

  &-components {
    &-panel__button {
      text-align: center !important;
      padding-right: 0 !important;
    }

    &-address-form {
      display: flex !important;
      flex-direction: row !important;
      gap: 20px !important;
      flex-wrap: wrap !important;

      &__address_2-toggle {
        @extend .button;
        @extend .button--transparent;

        padding-left: 25px !important;
        padding-right: 25px !important;
        margin-top: 0 !important;
        text-decoration: none !important;
      }
    }

    &-address-card {
      &__address-section {
        display: flex !important;
        flex-direction: column !important;

        span {
          &:after {
            display: none !important;
          }
        }
      }

      &__edit {
        text-transform: uppercase !important;
        letter-spacing: 0.1rem !important;
        text-decoration: none !important;
        padding: 0 !important;
      }
    }

    &-checkout-step {
      @extend .column;

      gap: 20px !important;

      &:after {
        display: none !important;
      }

      &__container {
        @extend .column;

        gap: 20px !important;
      }

      &__heading {
        margin: 0 !important;
        text-transform: uppercase !important;
      }

      &__description {
        margin-bottom: 0 !important;
      }
    }

    &-validation-error {
      position: relative !important;
      bottom: 0 !important;
      left: 0 !important;
      z-index: 100 !important;
      order: 3;
      margin-top: 10px;

      p {
        font-size: 0.56rem !important;
        padding: 0 !important;
        line-height: 20px !important;
      }
    }

    &-text-input {
      display: flex !important;
      flex-direction: column !important;
      width: 100% !important;
      white-space: wrap !important;
      margin-top: 0 !important;
      gap: 0 !important;

      input {
        background-color: transparent !important;
        color: $black !important;
        font-size: 1rem !important;
        line-height: 1.25rem !important;
        border: 1px solid $beige !important;
        border-radius: 0 !important;
        padding: 15px 25px !important;
        height: auto !important;
        order: 1;
        margin-top: 10px !important;
        width: 100% !important;
        overflow: visible !important;
        position: relative !important;
        z-index: 999 !important;

        &:focus {

        }
      }

      label {
        color: $black !important;
        position: relative !important;
        transform: none !important;
        left: 0 !important;
        top: 0 !important;
        order: 0;
        overflow: visible !important;
      }
    }

    &-textarea {
      @extend .input--text;

      border: 1px solid $beige !important;
      border-radius: 0 !important;
      padding: 15px 25px !important;
      margin-top: 20px !important;
    }

    &-select {
      &__container {
        display: flex !important;
        flex-direction: column !important;
        gap: 20px !important;
        border: 0 !important;
        border-radius: 0 !important;
        background: transparent !important;
        margin-top: 0 !important;
        height: auto !important;
      }

      label {
        position: relative !important;
        transform: none !important;
        left: 0 !important;
        overflow: visible !important;
      }

      select {
        font-size: 1rem !important;
        line-height: 1.25rem !important;
        color: $black !important;
        border: 1px solid $beige !important;
        border-radius: 0 !important;
        padding: 15px 25px !important;

        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        appearance: none !important;

        &::-ms-expand {
          display: none; /* Remove arrow in Internet Explorer */
        }

        background-image: url(../images/icon-filter.svg) !important;

        option {
          font-size: 0.62rem !important;
        }
      }

      svg, img {
        display: none !important;
      }
    }

    &-checkbox {
      position: relative !important;

      input {
        border: 2px solid $beige !important;
        border-radius: 0 !important;
        font-size: 0.72rem !important;
        height: 0.72rem !important;
        min-height: 0.72rem !important;
        width: 0.72rem !important;
        min-width: 0.72rem !important;
        position: absolute !important;
        top: 50%;
        transform: translateY(-50%);

        &:focus {
          outline: 1px solid $beige !important;
        }
      }

      svg, img {
        background-color: $beige !important;
        fill: $beige !important;
        width: 0.72rem !important;
        height: 0.72rem !important;
        margin-top: 0 !important;
        margin-left: 0 !important;
        position: absolute !important;
        top: 50%;
        transform: translateY(-50%);
      }

      span {
        margin-left: 1.25rem !important;
      }
    }

    &-button {
      @extend .button;
      @extend .button--black;
      @extend .transition;

      &:focus {
        outline: 0 !important;
        box-shadow: none !important;
      }
    }

    &-radio-control {
      &:after {
        display: none !important;
      }

      &__option {
        border-radius: 0 !important;
        box-shadow: none !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 2.5rem !important;
        padding-right: 0 !important;

        &:before {
          display: none !important;
        }

        input {
          background: none !important;
          border: 2px solid $dark-grey !important;
          border-radius: 0 !important;
          min-height: 16px !important;
          min-width: 16px !important;
          top: 8px !important;
          transform: none !important;

          &:checked:before {
            border-radius: 0 !important;
            min-height: 8px !important;
            min-width: 8px !important;
          }
        }
      }

      &__secondary-label {
        min-width: auto !important;
        width: 25% !important;

        span {
          font-style: italic !important;
          text-transform: none !important;
        }
      }
    }

    &-main {
      padding-right: 0 !important;
      flex: 1 !important;

      @media (max-width: 1000px) {
        width: 100% !important;
        order: 0 !important;
      }
    }

    &-sidebar {
      padding-left: 0 !important;
      top: 101px !important;
      position: relative !important;

      @media (max-width: 1000px) {
        margin-top: 0 !important;
        top: 0 !important;
        width: 100% !important;
        order: 1 !important;
      }

      &:before {
        content: "";
        display: none;
        z-index: 400;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $white;
        opacity: 0.75;
      }

      &.loading {
        &:before {
          display: block;
        }
      }

      &-layout {
        gap: 5% !important;

        @media (max-width: 1000px) {
          flex-direction: column-reverse !important;
          gap: 60px !important;
        }
      }
    }

    &-checkout-order-summary__title {
       display: none !important;
    }

    &-checkout-order-summary__content {
      display: block !important;
    }

    &-formatted-money-amount {
      font-weight: 400 !important;
    }

    &-order-summary {
      padding: 0 !important;

      /*
      & > div:first-child {
        display: none !important;
      }
      */

      &-item {
        border-bottom: 1px solid $light-beige !important;
        padding: 20px !important;

        &:last-child {
          border-bottom: 0 !important;
        }

        &__image {
          width: 100px !important;
          margin-top: 0 !important;
          padding-bottom: 0 !important;
          font-size: 0 !important;

          & > img {
            max-width: 120px !important;
            width: 120px !important;
          }
        }

        &__description {
          padding-left: 40px !important;
          padding-right: 20px !important;
          padding-bottom: 0 !important;

          ul {
            list-style-type: none !important;
            margin-top: 10px !important;
            margin-bottom: 0 !important;

            & ~ ul {
              display: none;
            }

            li {
              position: relative !important;
              line-height: 1.25 !important;

              &:before {
                content: "•";
                position: absolute;
                left: -1rem;
                color: $beige;
              }
            }
          }
        }

        &__quantity {
          display: none !important;
        }

        &__individual-price {
          font-style: italic !important;
          font-weight: 500 !important;
        }

        &__total-price {
          display: flex !important;
          flex-direction: column !important;
          justify-content: end !important;
          font-weight: normal !important;

          &:not(:first-child) {
            display: none !important;
          }

          bdi {
            font-style: italic !important;
          }

          span {
            line-height: 1 !important;
          }
        }
      }
    }

    &-product {
      &-name {
        font-family: $headings !important;
        font-size: 1rem !important;
        line-height: 1 !important;
      }

      &-metadata {
        &__description {
          display: none !important;
        }
      }

      &-price {
        display: none !important;
      }

      &-details {
        display: none !important;

        &__name {
          display: none !important;
        }
      }
    }

    &-totals-item {
      &__label {
        font-size: 1rem !important;
        font-weight: 500 !important;
      }

      &__value {
        font-size: 1rem !important;
        font-style: italic !important;
        font-weight: 500 !important;
      }
    }

    &-totals-footer-item {
      background-color: $beige !important;
      border-top: 1px solid $white !important;
      padding: 16px !important;

      &-tax-value {
        font-size: 1rem !important;
      }
    }

    &-totals-wrapper {
      padding: 0 !important;
    }

    &-totals-coupon {
      width: 100% !important;
      max-width: 400px !important;
      margin: 0 auto !important;

      &__form {
        button {
          flex: 1 0 auto !important;
          min-height: auto !important;
        }
      }

      &__input {
        input {
          border: 1px solid $black !important;
          color: $black !important;
          font-size: 1rem !important;
          line-height: 1.25rem !important;
          padding: 15px 25px !important;
          margin-top: 0 !important;
        }

        label {
          display: none !important;
        }
      }
    }

    &-totals-discount {
      &__coupon-list {
        span {
          text-transform: uppercase;
        }
      }
    }

    &-notice-banner {
      background-color: $light-beige !important;
      border: 0 !important;
      border-radius: 0 !important;

      &.is-error {
        & > svg, & > img {
          display: none !important;
        }
      }

      &.is-info {
        & > svg, & > img {
          background-color: $beige !important;
        }
      }

      & > .wc-block-components-button {
        background-color: $black !important;

        &:before {
          display: none !important;
        }

        svg, img {
          filter: brightness(100) !important;
        }
      }
    }
  }
}

.wp-block {
  &-heading {
    @extend .container;

    text-align: center !important;
  }

  &-woocommerce-checkout {
    @extend .container;

    margin: 0 auto !important;

    &-order-summary {
      &-block {
        border: 0 !important;

        .checkout-order-summary-block-fill {
          border: none !important;
          border-radius: 0 !important;
        }

        & > .wc-block-components-totals-wrapper:last-child {
          padding: 0 !important;
        }
      }

      &-cart-items-block {
        padding: 0 !important;
      }

      &-coupon-form-block {
        width: 100% !important;
      }

      &-totals-block {
        background-color: $light-beige !important;
        padding: 16px 0 !important;
        border-top: 1px solid $beige !important;

        @media (max-width: 730px) {
          padding: 16px 20px !important;
        }
      }

      &-shipping-block {
        display: none !important;
      }

      &-coupon-form-block {
        padding: 0 !important;
        border-top: 0 !important;

        button {
          @extend .button;
          @extend .button--black;
          margin: 0 !important;
          padding: 0 !important;
          text-align: center !important;

          &:before {
            background-color: $black !important;
          }

          &:hover {
            color: $white !important;

            &:before {
              background-color: $black !important;
            }
          }

          svg, img {
            display: none !important;
          }
        }
      }
    }

    &-shipping-methods-block {
      background-color: $light-beige !important;
      margin: 0 !important;
      border: 0 !important;
      border-top: 1px solid $white !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
      padding: 16px !important;
      gap: 40px !important;

      h2 {
        font-size: 1rem !important;
        font-family: $body !important;
        text-transform: none !important;
        letter-spacing: normal !important;
      }

      & > .wc-block-components-checkout-step__container {
        flex: 1 !important;
      }
    }

    & > .wp-block-woocommerce-checkout-order-summary-coupon-form-block {
      display: none !important;
    }

    &-payment-block {
      border: 0 !important;
      padding: 0 !important;
      margin: 0 !important;

      .wc-block-components-checkout-step__heading {
        display: none !important;
      }

      .wc-block-components-checkout-step__content {
        padding-top: 0 !important;
      }

      .wc-block-components-radio-control {
        &-accordion-option {
          padding: 25px 0 !important;
          border-radius: 0 !important;
          box-shadow: none !important;

          &__checked {
            border-radius: 0 !important;
            box-shadow: none !important;
          }
        }

        &-accordion-content {
          font-size: 0.72rem !important;
        }

        &__input {
          width: 0.72rem !important;
          height: 0.72rem !important;
          border: 2px solid $beige !important;

          &:checked:before {
            background: $beige !important;
            border-radius: 0 !important;
          }
        }
      }
    }
  }
}

body.retail {
  .wc-block-components-totals-footer-item-tax {
    display: none
  }

  .includes_tax{
    display: none !important;
  }
}

.checkout-order-summary-block-fill .wp-block-woocommerce-checkout-order-summary-coupon-form-block {
  display: none !important;
}

.checkout__coupon .wc-block-components-panel__button {
  display: block !important;
}