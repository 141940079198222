.input[type=text], .input[type=email], .input--text, .input-text {
  padding: 20px 25px !important;
  font-family: $body !important;
  font-size: 1rem !important;
  border: 1px solid $light-beige !important;
  line-height: 1rem !important;
  border-radius: 0 !important;

  &:active, &:focus, {
    border-color: $beige !important;
    outline: none !important;
  }
}