.category {
  &__container {
    @extend .container;

    .woocommerce-result-count {
      display: none;

      @media (min-width: 750px) {
        display: block;
      }
    }
  }

  &__products {
    padding: 0;
    width: 100%;
    @extend .product-grid;

    @media (min-width: 750px) {
      padding: 30px;
    }

    .product-card {
      margin: 0;
      padding: 0;
    }

    .product-card__box {
      margin-left: 0 !important;
    }

    .product-card__footer {
      margin-left: 0 !important;
    }
  }

  &__intro {
    text-align: center;
    padding: 35px 0 35px 0;
    letter-spacing: -0.005rem;
    max-width: 1066px;
    margin: 0 auto;
    font-size: 0.81rem;

    @media (min-width: 750px) {
      font-size: 1rem;
      padding: 35px 0 70px 0;
    }

    h1 {
      font-size: 1.75rem;
      text-transform: uppercase;
      letter-spacing: .305rem;
      margin-bottom: 36px;

      @media (min-width: 750px) {
        font-size: 2.27rem;
      }
    }
  }

}