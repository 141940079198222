html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-size: 22px;
}

body {
  width: 100%;
  padding: 0;
  margin: 0;
  font-weight: 400;
  position: relative;
  font-family: $body;
  background: $white;
  color: $black;
  letter-spacing: 0;
  line-height: 1.7;

  &:not(.has-header--transparent) {
    margin: 0;

    & .breadcrumb, & ~ div section:first-of-type:not(.footer-nav):not(.dashboard), & .status-publish > section {
      margin-top: 101px;

      @media (max-width: 1200px) {
        margin-top: 81px;
      }
    }

    & .breadcrumb ~ section, & .breadcrumb ~ .status-publish > section {
      margin-top: 0;

      @media (max-width: 1200px) {
        margin-top: 0;
      }
    }
  }
}

.has-design-your-room {
  max-width: 100vw;
  overflow-x: hidden;
}

#wpadminbar {
  @media (max-width: 600px) {
    position: fixed !important;
  }
}

.container {
  max-width: clamp(420px, 100%, 1620px);
  margin: 0 auto;
  padding: 0 15px;

  @media (min-width: 1200px) {
    padding: 0 30px;
  }
}

* {
  box-sizing: border-box;
}

.hidden{
  display: none;
}

body.home .breadcrumb {
  display: none !important;
}

.large-desktop {
  @media (max-width: 1920px) { display: none !important; }

  & ~ .desktop {
    @media (min-width: 1921px) { display: none !important; }
  }
}

.desktop {
  @media (max-width: 1024px) { display: none !important; }
}

.tablet {
  @media (min-width: 1025px) { display: none !important; }
  @media (max-width: 1024px) { display: block; }
  @media (max-width: 768px) { display: none !important; }
}

.phone {
  @media (max-width: 768px) { display: block; }
  @media (min-width: 769px) { display: none !important; }
}