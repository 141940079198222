.category__pagination {
  @extend .container;

  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 20px;

  height: 52px;
  margin-top: 40px;

  @media (max-width: 850px) {
    flex-wrap: wrap;
  }

  .prev, .next {
    display: block;
    width: 120px;
    border: 1px solid $beige;
    text-align: center;
  }

  .prev {
    @media (max-width: 850px) {
      order: 1;
    }
  }

  .next {
    @media (max-width: 850px) {
      order: 2;
    }
  }

  &--pages {
    list-style-type: none;
    margin: 0 !important;
    padding: 0 !important;

    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0;

    @media (max-width: 850px) {
      width: 100%;
      flex: 1 0 auto;
    }

    li {
      border: 1px solid $beige;
      width: 52px;
      height: 100%;
      text-align: center;

      &:not(:last-child) {
        border-right: 0 !important;
      }
    }

    .pagination-pages span {
      background-color: $beige;
    }

    @media (max-width: 850px) {
      order: 0;
    }
  }

  a, span {
    display: block;
    width: 100%;
    color: $black;
    text-decoration: none;
    line-height: 52px;
  }
}