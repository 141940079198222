.instagram-feed {

  @media (min-width: 900px) {
    padding: 80px 0;
    display: block;
  }

  &__container {
    @extend .container;
    max-width: clamp(420px, 100%, 1200px);
  }

  &__header {
    text-align: center;

    h3 {
      font-size: 0.72rem;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.24rem;
      font-weight: 600;
    }

    h2 {
      font-family: $body;
      font-size: 1.45em;
      margin: 15px auto 25px auto;

      a {
        color: $black;
        text-decoration: none;
      }
    }
  }
}