.table {
  width: 100%;
  border: 1px solid $light-grey !important;
  margin-bottom: 20px !important;

  tr, th, td {
    padding: 0;
  }

  th, td {
    padding: 0 15px !important;
  }

  th {
    border-right: 1px solid $light-grey;
    text-align: right;
    font-size: 0.56rem;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-weight: normal !important;
    color: $black;
    font-family: $body;
    width: 20% !important;
  }
}