.hero {
  height: calc(100vh - 120px);
  position: relative;
  overflow: hidden;

  &__picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($black, 0.2);
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__container {
    @extend .container;
    max-width: clamp(420px, 100%, 720px);
    position: relative;
    z-index: 999;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 70px;
    text-align: center;
    padding: 0 40px;

    h1 {
      color: $white;
      font-size: 6.5vh;
      text-transform: uppercase;
      line-height: 1;

      @media (min-width: 500px) {
        font-size: 2.27rem;
      }
    }
  }
}