.post-teaser {
    width: 300px;
    flex: 0 1 300px;

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 20px;
    }

    &__top {
        position: relative;
        aspect-ratio: 16 / 9;

        @media (max-width: 800px) {
            aspect-ratio: 4 / 3;
        }

        a {
            color: $black;
            text-decoration: none;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }

    &__date {
        width: max-content;
        background-color: $white;
        position: relative;
        top: 100%;
        left: 20px;
        transform: translateY(-100%);
        z-index: 5;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        padding: 15px 15px 10px 15px;

        span {
            font-size: 28px;
            display: block;
            padding-bottom: 12px;
        }
    }

    &__title {
        margin: 0;

        a {
            color: $black;
            text-decoration: none;
        }
    }

    &__author {
        font-size: 14px;
        font-style: italic;

        a {
            color: $beige;
            text-decoration: none;
        }
    }

    &__excerpt {
        font-size: 14px;
        margin: 30px 0 0 0;
    }
}