.my-account {

  &__container {
    @extend .container;
    max-width: 800px;

    input {
      @extend .input--text;
    }

    .form-row {
      padding: 0 !important;
      margin: 0 !important;
      float: none !important;
      width: 100% !important;
      text-align: center !important;
    }

    button {
      margin: 15px auto !important;
    }
  }

  &__intro {
    text-align: center;
    padding: 35px 0 35px 0;
    letter-spacing: -0.005rem;
    max-width: 1066px;
    margin: 0 auto;
    font-size: 0.81rem;

    @media (min-width: 750px) {
      font-size: 1rem;
      padding: 35px 0 70px 0;
    }

    h1 {
      font-size: 1.75rem;
      text-transform: uppercase;
      letter-spacing: .305rem;
      margin-bottom: 36px;

      @media (min-width: 750px) {
        font-size: 2.27rem;
      }
    }
  }

  .cart table .product__image img {
    aspect-ratio: 1/1;
    width: 100%;
    height: auto
  }

  .woocommerce ul.order_details {
    padding: 0;
    margin: 0;
  }

  .woocommerce ul.order_details li {
    float: none;
    margin: 0 0 15px 0;
    font-size: 0.59rem;
    text-align: center;
    padding: 0;
  }

  @media (max-width: 990px) {


    .product__details {
      flex-direction: column;
      gap: 0 !important;
    }

    .product__title {
      width: 100%;
      clear: both;
      padding: 0 !important;
    }

    .woocommerce td.product-name .wc-item-meta {
      width: 100%;
      clear: both;
      padding: 0;
      margin: 0
    }

    .cart table .product__image img {
      margin-bottom: 15px
    }
  }
}

.woocommerce form .form-row .required {
  color: $beige !important;
}