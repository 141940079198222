.footer-nav {
  background: $beige;

  &__container {
    @extend .container;
    position: relative;

    &:before {
      content: '';
      width: calc(100% - 60px);
      height: 1px;
      background: $light-beige;
      position: absolute;
      top: 0;
      left: 30px;
    }
  }

  &__above {
    padding: 150px 0 140px 0;
    display: flex;
    gap: 20px;
  }

  &__column {
    width: 25%;
    text-align: left;

    h4 {
      font-size: 0.56rem;
      text-transform: uppercase;
      letter-spacing: 0.15rem;
      font-weight: normal;
      color: $black;
      font-family: $body;
      margin: 0 0 30px 0;
    }

    ul, li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    a, p {
      font-size: 0.82em;
      color: $black;
      text-decoration: none;
    }

    &--email {
      padding-top: 5px;
    }

    &--social {
      ul {
        display: flex;
        gap: 10px;
        flex-direction: column;
        padding-top: 5px;

        li {
          a {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            gap: 15px;

            img {
              max-width: 20px;
              width: 100%;
              height: auto;
              margin: 0;
            }

            span {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__email {
    position: relative;

    input[type=email] {
      font-size: 0.72rem !important;
      padding: 15px 20px !important;
      width: 100% !important;
    }

    button {
      position: absolute;
      background: transparent;
      width: 34px;
      height: 34px;
      border: 0;
      cursor: pointer;
      top: 10px;
      right: 10px;
      z-index: 3;

      &:after {
        position: absolute;
        content: "";
        background: $beige;
        width: 34px;
        height: 34px;
        top: 0;
        right: 0;
        z-index: -2;
      }

      &:before {
        @extend .transition;

        position: absolute;
        content: "";
        background: darken($beige, 5%);
        width: 0;
        height: 34px;
        top: 0;
        left: 0;
        z-index: -1;
      }

      &:hover {
        &:before {
          width: 34px;
        }
      }

      img {
        width: 14px;
        height: auto;
        margin: 0;
        position: relative;
        top: 2px;
      }
    }
  }

  &__below {
    border: 1px solid $light-beige;
    border-width: 1px 0;
    padding: 30px 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;
    gap: 20px;
  }

  &__logo {
    display: flex;
    align-content: center;
    align-items: center;
    width: 100%;

    img {
      width: auto;
      height: 100%;
      max-height: 20px;
      margin: 0;
    }
  }

  &__payment-methods {
    display: flex;
    align-content: center;
    width: 100%;
    justify-content: flex-end;
    gap: 6px;

    img {
      display: flex;
      height: 28px;
      max-height: 28px;
      width: auto;
      margin: 0;
      border: 1px solid $light-beige;
      border-radius: 4px;
    }
  }

  &__small-print {
    font-size: 0.45rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-weight: normal;
    color: $dark-grey;
    font-family: $body;
    padding: 40px 0;
    display: flex;
    gap: 30px;

    a {
      color: $black;
      text-decoration: none;
    }
  }

  &__text {
    width: 100%;
    text-align: right;

    p {
      margin: 0;
      padding: 0;
      display: inline;
    }
  }

  &__links {
    width: 100%;

    ul, li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ul {
      display: flex;
      gap: 25px;
    }
  }

  @media (max-width: 1200px) {

    &__container:before {
      width: calc(100% - 30px);
      left: 15px;
    }

    &__above {
      flex-direction: column;
      padding: 30px 0 0 0;
      gap: 0;
    }

    &__column {
      flex-basis: 100%; /* Ensures items take up full width */
      flex-grow: 1; /* Allows the items to grow and take full available space */
      width: 100%;

      &--email {
        order: 1;
      }

      &--social {
        order: 2;
        padding: 25px 0;

        h4 {
          display: none;
        }

        ul {
          flex-direction: row;
          gap: 0;
          justify-content: space-between;
          padding: 0;

          li {
            width: 30px;

            a {
              img {
                max-width: 30px;
              }

              span {
                display: none;
              }
            }
          }
        }
      }

      &--menu {
        order: 3;
      }

      &--support {
        order: 4;
      }
    }


    &__below {
      flex-direction: column;
    }

    &__payment-methods {
      justify-content: flex-start;
    }

    &__small-print {
      flex-direction: column;
    }

    &__links {
      order: 1;
    }

    &__text {
      order: 2;
      text-align: left;
    }

    &__collapsible {
      border-top: 1px solid $light-beige;

      h4 {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        gap: 10px;
        cursor: pointer;
        padding: 25px 0;
        margin: 0;
      }

      ul {
        height: 0;
        transition: all 0.5s ease-in-out;
        overflow: hidden;
      }

      &.active {
        .footer-nav__expand-icon:before {
          transform: rotate(-270deg);
        }

        ul {
          height: fit-content;
          padding-bottom: 40px;
        }
      }

    }

    &__expand-icon {
      display: block;
      width: 15px;
      height: 15px;
      position: relative;

      &:before {
        /*|*/
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background: $black;
        position: absolute;
        left: 7px;

        transform: rotate(0deg);
        transition: all 0.5s ease-in-out;
      }

      &:after {
        /*-*/
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: $black;
        position: absolute;
        top: 7px;
      }
    }

    &__logo {
      img {
        height: 15px;
      }
    }

  }
}