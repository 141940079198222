.mobile-navigation {
  padding-bottom: 180px;
  height: calc(100vh - 100px); //Deduct header height
  min-height: 100px !important;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;

  &__primary {
    .mobile-navigation__primary-item {
      display: none !important; //Not sure what's generating this
    }
  }

  &__item {
    @extend .transition;

    font-size: 0.72rem;
    padding: 12px 15px;
    border: 1px solid $light-beige;
    border-width: 0 0 1px 0;
    text-align: left;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    cursor: pointer;
    color: $black;
    text-decoration: none;

    a {
      color: $black;
      text-decoration: none;
    }

    &:hover {
      background: $light-beige;
    }

    &--back {

    }
  }

  &__arrow {
    img {
      height: 15px;
      width: auto;
    }
  }

  &__secondary {
    column-count: 2;
    column-gap: 15px;
    padding: 60px 15px 30px 15px;
    text-align: left;

    &-item {
      @extend .transition;
      color: $black;
      text-decoration: none;
      font-size: 0.82rem;
      width: 100%;
      display: inline-block;

      &:hover {
        color: $beige;
      }
    }
  }

  &__social {
    display: flex;
    gap: 45px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 0 15px;
  }

  &__icon {
    width: 30px;
    height: auto;

    img {
      display: block;
      margin: 0;
      width: 100%;
      height: 100%
    }
  }
}