.form-login {
  &__container {
    @extend .container;
    padding-top: 40px;
    max-width: 640px;

    h1 {
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.24rem;
      margin: 0 0 30px 0
    }
  }

  &__options {
    display: flex;
    gap: 70px;
    
    @media (max-width: 600px) {
      gap: 35px;
    }
  }

  &__option {
    font-size: 0.72rem;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.24rem;
    font-weight: normal;
    width: 100%;
    padding: 30px;
    border-bottom: 1px solid $dark-grey;
    color: $dark-grey;
    cursor: pointer;

    @media (max-width: 600px) {
      gap: 20px;
    }

    &.active, &:hover {
      color: $black;
      border-bottom-color: $beige;
    }
  }

  &__tab {
    text-align: center;

    .notice {
      margin-top: 0;
    }

    h2 {
      font-size: 1.45rem;
      text-transform: uppercase;
      letter-spacing: 0.24rem;
      margin: 90px 0 20px 0;

      @media (max-width: 600px) {
        margin-top: 30px;
      }
    }

    input[type=text], input[type=password], input[type=email] {
      @extend .input--text;
      width: 100%;
      display: block;
      margin-bottom: 15px;
    }

    .input-wrapper {
      position: relative;

      &__end {
        position: absolute;
        top: 0;
        right: 20px;
        display: flex;
        align-content: center;
        align-items: center;
        height: 100%;

        a {
          font-size: 0.68rem;
          text-decoration: none;
          color: $dark-grey;
          height: auto;
        }
      }
    }

    .checkbox {
      margin-right: 15px;

      &__text {
        font-size: 0.68rem;
        position: relative;
        top: -1px;
      }
    }

    button {
      @extend .button--transparent;
      background: $white !important;
      font-size: 0.56rem !important;
      text-transform: uppercase !important;
      text-align: center !important;
      letter-spacing: 0.15rem !important;
      font-weight: normal !important;
      color: $black !important;
      text-decoration: none !important;
      padding: 15px 45px !important;
      /*display: inline-block !important;*/
      cursor: pointer !important;
      font-family: $body !important;

      &:hover{
        background: $black !important;

        &:before{
          background: $black !important;
          width: 100% !important;
        }
      }
    }

    button + p {
      font-size: 0.68rem;
      margin-bottom: 0 !important;

      span {
        color: $dark-grey;
        cursor: pointer;
      }
    }

    &--sign-up {
      p {
        font-size: 0.68rem;

        a {
          text-decoration: none;
          color: $dark-grey;
        }
      }
    }
  }
}