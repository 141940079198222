.search {
  position: relative;

  &__input {
    width: 400px;
  }

  &__button {
    background: transparent;
    height: 100%;
    border: 0;
    cursor: pointer;
    position: absolute;
    right: 10px;

    img {
      width: 30px;
      height: auto;
    }
  }
  
  @media (max-width: 1200px) {

  }
}