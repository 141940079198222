// SCSS for blog-product section
.blog-product {
  width: 100%;
  max-width: 500px;
  display: block;
  margin: 1em auto;
  border: 1px solid $light-grey;

  &__container {
    padding: 15px;
  }

  .product-card {
    &__box {
      margin-left: 0 !important;
      aspect-ratio: 16 / 9;

      &:before, &:after {
        display: none !important;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    &__footer {
      margin-left: 0 !important;

      h3 {
        a {
          color: $black !important;
        }
      }

      & + .button {
        margin-top: 20px !important;
        width: 100% !important;
      }
    }
  }

  &__loading, &--empty {
    display: block;
    width: 100%;
    text-align: center;
  }
}