.message {
  position: fixed;
  bottom: -100px;
  opacity: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition: all 0.75s ease;
  z-index: 99999;
  padding: 0 15px;

  &.visible {
    bottom: 30px;
    opacity: 1;
  }

  &__inner {
    background: $light-beige;
    border: 1px solid $black;
    text-align: center;
    width: fit-content;
    max-width: 800px;
    padding: 15px 45px;
    margin: 0 auto;
    font-size: 0.71rem;
    position: relative;
    z-index: 99999;

    a {
      color: $black;
      display: block;
      clear: both;
    }
  }
}