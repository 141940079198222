// SCSS for text-block section

.text-block {
  padding: 60px 0;

  @media (max-width: 800px) {
    padding: 40px 0;
  }

  &__container {
    @extend .container;
    @extend .column;
  }

  &__heading {
    text-transform: uppercase;
    text-align: center;
  }
}