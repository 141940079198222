.our-story {
  background: $beige;
  padding: 100px 0;

  &__container {
    @extend .container;
    max-width: clamp(420px, 100%, 1050px);
    display: flex;
    flex-direction: column;
    gap: 45px;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  &__content {
    text-align: center;

    h2 {
      font-family: $body;
      font-size: 1.18em;
      margin: 0;
      font-weight: 200 !important;

      @media (min-width: 900px) {
        font-size: 1.45em;
      }
    }
  }

  &__button {
    @extend .button;
  }
}