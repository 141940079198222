.fw-button {
  @extend .transition;

  margin: 0 !important;
  background-color: transparent !important;
  background-image: url('../images/product-like-inactive.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  opacity: 1 !important;

  &:hover, &--loading {
    opacity: 0.25 !important;
  }

  &--active {
    background-image: url('../images/product-like-active.svg');
  }

  &-icon {
    display: none !important;

    &:before {
      display: none !important;
    }
  }

  &-text {
    display: none;
  }
}

.fw-popup {
  background-color: $white !important;
  border: 1px solid $dark-grey !important;
  margin-top: 0 !important;
  width: calc(100% - 30px) !important;
  max-width: 450px !important;

  @media (max-width: 700px) {
    left: unset !important;
    right: 15px !important;
  }

  &-close {
    color: $beige !important;
    text-decoration: none !important;
    position: relative !important;
    float: right !important;
    right: unset !important;
    top: unset !important;
    margin: 1rem 1rem 0 0 !important;
    padding: 0 0 0 0.5rem !important;
  }

  &-content {
    > p {
      margin-top: 0 !important;
    }

    p {
      font-size: .79rem !important;
      line-height: 1.5;

      strong {
        @extend .h3;
        font-size: 1rem;
        line-height: 1;
        margin: 0 0 15px 0;
        font-family: $headings !important;
        font-weight: 400 !important
      }
    }

    a {
      color: $black;
    }

    p + p {
      text-transform: none !important;
      letter-spacing: normal !important;

      a {
        color: $beige !important;
        text-decoration: none !important;
      }
    }

    p + p + ul {
      display: none !important;
    }
  }

  &-items {
    margin: 15px 0 0 0 !important;
    padding: 0 !important;
    overflow: visible !important;
  }

  &-item {
    display: flex !important;
    flex-direction: row !important;
    gap: 10px !important;
    justify-content: start !important;
    align-items: center !important;
    font-size: .79rem !important;

    input {
      appearance: none !important;
      margin: 0 !important;
      border: 2px solid $beige !important;
      border-radius: 0 !important;
      font-size: .72rem !important;
      height: .72rem !important;
      min-height: .72rem !important;
      width: .72rem !important;
      min-width: .72rem !important;
      position: relative !important;
      cursor: pointer !important;

      &:before {
        content: "" !important;
        display: block !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        background-color: transparent !important;
      }

      &:focus {
        outline: 1px solid $beige !important;
        outline-offset: 2px !important;
      }

      &[checked] {
        &:before {
          background-color: $beige !important;
        }
      }
    }

    label {
      flex: 1 !important;
    }

    a {
      color: $beige !important;
      text-decoration: none !important;
    }
  }

  &-footer {
    border-top: 1px solid $light-beige !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: start !important;
    gap: 10px !important;

    input {
      flex: 1 !important;
      font-size: 0.72rem !important;
      font-family: $body !important;
      border: 1px solid $light-beige !important;
      padding: 10px !important;
      margin: 0 !important;
    }

    button {
      flex: 0 !important;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

.wishlist {
  &.cart table div.quantity {
    margin-top: 23px !important;
  }
}

.wishlist__options, .woocommerce table.shop_table_responsive tr td.wishlist__options {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: wrap;

  a.added_to_cart {
    padding: 0 !important;
    letter-spacing: 0.15rem !important;
    font-family: $body !important;
    font-weight: normal !important;
    color: $black !important;
    text-transform: uppercase !important;
    font-size: 0.56rem !important;
    text-decoration: none !important;
    border: 0 !important;
    width: 100% !important;
    cursor: pointer !important;
    text-align: center !important;
    line-height: 0.8rem;
    margin-top: 15px !important;
  }

  @media (max-width: 770px) {
    padding: 0 !important;
    text-align: center !important;
  }
}

.wishlist__button {
  letter-spacing: 0.15rem !important;
  font-family: $body !important;
  font-weight: normal !important;
  color: $black !important;
  text-transform: uppercase !important;
  font-size: 0.56rem !important;
  text-decoration: none !important;
  padding: 8px 30px !important;
  border: 0 !important;
  width: 100% !important;
  cursor: pointer !important;
  text-align: center !important;
  margin-top: 23px !important;
  line-height: 0.8rem;


  @media (max-width: 770px) {
    margin-top: 0 !important;
    margin-bottom: 15px !important;
  }

  &--select-options {
    background: $beige;
  }

  &--out-of-stock {
    cursor: none !important;
    color: $dark-grey !important;
    background: $light-grey;
  }

  &--add-to-bag {
    background: $black;
    color: $white !important;
  }

}