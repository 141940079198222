.header {
  @extend .transition--slow;
  border-bottom: 1px solid $light-beige;
  background: $white;
  display: none;
  position: fixed;
  z-index: 9999;
  width: 100vw;
  top: 40px;

  &--transparent {
    background: transparent;
    border-bottom: 1px solid rgba($beige, 0.3);
    color: $white;

    .header__logo {
      img {
        filter: brightness(100);
      }
    }

    .header__icon {
      img {
        filter: brightness(100);
      }
    }

    .header__item {
      &, path {
        color: $white;
      }
    }
  }

  @media (min-width: 1200px) {
    display: block;
  }

  &__container {
    @extend .container;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;
    gap: 20px;
    min-height: 100px;
    transition: all 300ms ease;
  }

  &__logo {
    width: 328px;
    min-width: 215px;
    display: flex;
    align-items: center;

    a {
      width: 100%;
      height: auto;
    }

    img {
      filter: brightness(1);
      transition: all 0.5s ease-in-out;
    }
  }

  &__nav {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__icons {
    display: flex;
    width: fit-content;
    gap: 20px;
    align-items: center;

    @media (min-width: 1400px) {
      gap: 40px;
    }
  }

  &__icon {
    cursor: pointer;
    width: 20px;

    img {
      @extend .transition--slow;
      width: 20px;
      display: block;
      margin: 0;
      filter: brightness(1);
      max-width: unset !important;
    }

    &:hover {
      img {
        filter: invert(88%) sepia(29%) saturate(461%) hue-rotate(20deg) brightness(90%) contrast(85%); //Convert $black to $beige
      }
    }

    &--bag {
      position: relative;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 15px;
    height: 100%;

    @media (min-width: 1400px) {
      gap: 30px;
    }
  }

  &__item {
    font-size: 0.72rem;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin: 0;
    padding: 0;
    color: $black;
    text-decoration: none;
    text-align: center;
    align-content: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.5s ease-in-out;
  }

  &__subnav {
    display: flex;
    gap: 15px;
    flex-direction: column;
    padding: 40px 0 55px 0;
    width: fit-content;
    text-align: center;
    border-top: 1px solid $beige;
    position: absolute;
    top: auto; /* Maintains natural vertical positioning */
    left: 0; /* Will be overridden by JavaScript */
    margin-top: -1px;

    .header__item {
      font-size: 0.82rem;
      color: $dark-grey;

      &:hover {
        color: $black;

        &:after {
          display: none;
        }
      }
    }
  }

  &__dropdown {
    position: absolute;
    width: 100%;
    display: none;
    background: $white;
    border-top: 1px solid $light-beige;
  }

  &__bag-quantity {
    background: $beige;
    color: $black;
    font-family: $sans;
    text-decoration: none;
    position: absolute;
    top: -8px;
    right: -10px;
    font-size: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0.4rem;
    min-width: 14px;
    min-height: 14px;
    padding: 2px;
    border-radius: 50%;
    white-space: nowrap; // Prevent wrapping

  }

  &__scroll {
    .header__container {
      min-height: 60px;
    }
  }
}

.admin-bar .header {
  top: 72px;

  @media (max-width: 782px) {
    top: 86px;
  }
}

.has-header--transparent .header__scroll {
  background: $white;
  color: $black;
  border-bottom: 1px solid $light-beige;

  .header__logo, .header__icon {
    img {
      filter: brightness(1);
    }
  }

  .header__item {
      color: $black;
  }
}

.has-design-your-room .header {
  position: absolute !important;
  top: 40px !important;
}