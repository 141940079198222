.breadcrumb {
  font-size: 0.56rem;
  overflow-x: auto;

  &__container {
    @extend .container;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 9999px;
    display: flex;
    white-space: nowrap;

    @media (min-width: 1200px) {
      padding-top: 35px;
      padding-bottom: 35px;
      width: auto;
    }

    @media (max-width: 1000px) {
      max-width: max-content !important;
      margin: 0 auto 0 0 !important;
    }
  }

  &__item {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.15rem;
    font-weight: normal;
    color: $black;
    text-decoration: none;

    a {
      color: $dark-grey;
      text-decoration: none;
    }
  }

  &__delimiter {
    display: inline-block;
    padding: 0 20px;

    &:before {
      content: '|';
      color: $light-grey;
    }
  }
}