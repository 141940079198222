.what-our-customers-say {
  padding: 80px 0 120px 0;
  overflow: hidden;
  position: relative;

  &__container {
    /*@extend .container;*/
  }

  &__heading {
    font-size: 1.45rem;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.24rem;
    margin: 0 0 80px 0;
  }

  &__list {
    @include faded-edges(40px);

    @media (min-width: 1300px) {
      @include faded-edges(220px);
    }

    .slick-track {
      display: flex;
      align-items: stretch; /* Ensures children stretch to match parent's height */
      align-content: stretch;
      min-width: 100%;
      box-sizing: border-box; /* Include padding and border in size calculations */
    }
  }

  &__slide {
    background: $beige;
    text-align: center;
    margin: 0 10px;
    padding: 35px 35px;
    font-size: 0.89rem;
    box-sizing: border-box; /* Include padding and border in size calculations */
    flex: 1 1 auto; /* Allows slides to grow and shrink as needed */
    height: unset;

    display: flex !important;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;

    @media (min-width: 750px) {
      padding: 75px 65px;
      font-size: 1rem;
    }
  }

  &__quote {
    width: 100%;
    margin: 0 0 35px 0;
  }

  &__name {
    font-style: italic;
    margin: 0;
  }

  &__stars {
    height: 37.4px;
    display: flex;
    justify-content: center;
    gap: 5.5px;

    img {
      width: 13px;
      height: auto;
      display: inline-block;
    }
  }
}