// SCSS for delivery-table section
.delivery-table {
    @extend .delivery-header;
    padding: 0 0 80px 0;

    &__container {
        @extend .container;
        padding: 0 100px;
    }

    &__table {
        width: 100%;
        max-width: 640px;
        margin: 0 auto;
        border-collapse: collapse;

        tr {
            background-color: $light-beige;
            border-bottom: 1px solid $white;
        }

        tr:first-of-type {
            border-bottom: 1px solid $beige;
        }

        th {
            background-color: $white;
            font-family: "Cormorant SC","Times New Roman",Times,serif;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 3px;
        }

        td:nth-child(2) {
            font-style: italic;
        }

        th,
        td {
            height: 100px;
            line-height: 1.25;
            text-align: right;
            padding: 0 20px;
            margin: 0;
        }

        th:first-child,
        td:first-child {
            text-align: left;
        }
    }

    @media (max-width: 1200px) {
        &__container {
            padding: 0 50px;
        }
    }

    @media (max-width: 800px) {
        &__container {
            padding: 0 20px;
        }
    }
}