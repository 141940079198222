.woocommerce-pagination {
  margin-top: 40px !important;

  ul {
    border: 1px solid $beige !important;
    border-right: 0 !important;

    li {
      border-right: 1px solid $beige !important;

      a {
        color: darken($beige, 10%) !important;

        &:hover {
          background: $light-beige !important;
        }
      }

      span {
        background: $light-beige !important;
        color: $black !important;
      }
    }
  }
}