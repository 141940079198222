.quantity {
  background: $black;
  color: $white;
  margin: 0 !important;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 0;

  &__button {
    font-size: 0.59rem !important;
    cursor: pointer;
    width: 25px;
    text-align: center;

    &--less {

    }

    &__more {

    }
  }

  form {
    @extend .quantity;
  }

  input {
    background: transparent !important;
    outline: 0 !important;
    border: 0 !important;
    color: $white !important;
    font-family: $body !important;
    font-size: 0.59rem !important;
    appearance: textfield !important;
    text-align: center !important;
    padding: 0 !important;

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
  }
}