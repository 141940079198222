.product-card {
  padding: 0 10px;

  &__box {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    overflow: visible;
    position: relative;
    margin-left: 40px;
    z-index: 5;
    background: $light-beige;

    &:hover {
      .product-card__tools {
        opacity: 1;
      }
    }

    &:before {
      content: "";
      background-color: $white;
      display: block;
      width: 20px;
      height: 100%;
      position: absolute;
      left: -20px;
      top: 0;
      z-index: 500;
    }

    &:after {
      content: "";
      background-color: $white;
      display: block;
      height: 20px;
      width: 100%;
      position: absolute;
      top: -20px;
      left: 0;
      z-index: 500;
    }

    a {
      position: relative;
      display: flex;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:hover {
      .product-card__sale-badge {
        opacity: 0;
      }
    }
  }

  &__sale-badge {
    transition: opacity 0.5s ease-in-out;
    z-index: 99;
    position: absolute !important;
    top: 20px;
    left: -29px;
    width: 120px;
    text-align: center;
    height: fit-content;
    transform: rotate(-45deg);
    background: darken($beige, 5%);
    color: $white;
    border-radius: 0;
    margin: 0;
    min-height: 0;
    font-size: 0.59rem;
    font-weight: normal;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    line-height: 1;
    padding: 5px 0;
    text-shadow: -1px 1px 2px mix($beige, $black, 70%);
  }

  /* &__like {
     width: 16px;
     height: 16px;
     position: absolute;
     top: 14px;
     right: 16px;
     display: flex;
     cursor: pointer;
     z-index: 999;

     svg, path {

     }

     &:hover {
       svg, path {
         filter: brightness(100);
       }
     }
   }*/

  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  &__tools {
    @extend .transition;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    width: calc(100% - 20px);
    z-index: 1000;
    opacity: 0;

    @media (max-width: 600px) {
      display: none;
    }
  }

  &__like {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 14px;
    right: 16px;
    display: flex;
    cursor: pointer;
    z-index: 999;
    font-size: 0 !important;

    .wlr-product-message {
      display: none !important;
    }

    a.button {
      display: none !important;
    }
  }

  &__options {
    background: $white;
    padding: 15px 20px 20px 20px;
    position: relative;
    display: none;

    &.active {
      display: block;
    }

    strong {
      font-size: 0.5rem;
      letter-spacing: 0.07rem;
      line-height: 0.8rem;
      text-transform: uppercase;
      text-align: center;
      display: block;
      margin: 0 0 15px 0;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
      align-content: center;
      justify-content: space-around;
      align-items: stretch;

      li {
        font-size: 0.69rem;
        color: $dark-grey;
        border: 1px solid $beige;
        background: $white;
        padding: 0 5px;
        text-align: center;
        cursor: pointer;
        width: 40%;
        align-items: center;
        align-content: center;
        display: flex;
        line-height: 0.69rem;
        justify-content: center;
        min-height: 50px;
        flex: 1 0 auto;

        &.active, &:hover {
          background: $beige;
          color: $black;
        }
      }
    }
  }

  &__close {
    width: 9px;
    height: 9px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;

    svg, img {
      &, path {

      }
    }
  }

  &__icon {
    &--loader {
      display: none;
    }

    &--bag {
      display: flex;
    }
  }

  &__button {
    @extend .button;
    @extend .button--product;
    width: 100%;
    height: 30px;
    display: none;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 5px;
    background: $light-beige !important;
    padding: 15px !important;
    font-size: 0.4rem !important;

    @media (min-width: 750px) {
      font-size: 0.56rem !important;
      height: 50px;
      gap: 10px;
    }

    &:before {
      background: $beige !important;
    }

    &:after {
      background: transparent !important;
    }

    &, &:hover {
      border-color: $light-beige !important;
      color: $black !important;
    }

    &.active {
      display: flex;
    }

    &--add {


      &.working {
        .product-card__icon--loader {
          display: flex;
        }

        .product-card__icon--bag {
          display: none;
        }
      }

      svg, img {
        width: 18px;
        height: auto;
        display: inline-block;
      }

      svg, path {

      }
    }
  }

  &__footer {
    margin-left: 40px;

    h3 {
      font-size: 1rem;
      line-height: 1;
      margin: 20px 0 0 0;

      a {
        color: $black;
        text-decoration: none;
      }
    }

    span {
      color: $black;
      font-size: 0.72rem;
      font-style: italic;
      margin: 10px 0 0 0;
    }

    del, del span {
      color: $dark-grey !important;
      font-size: 0.69rem !important;
      opacity: 1 !important;
    }
  }

  &__description {
    font-size: 0.72rem;
  }
}

body.trade .product-card__sale-badge {
  display: none !important;
}