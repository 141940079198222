.product-page {
  @extend %clearfix;

  &__container {
    @extend .container;
    max-width: 1220px;
  }

  &__primary {
    margin-bottom: 80px;

    .container {
      display: block;
    }

    @media (min-width: 1200px) {
      margin-top: 15px;

      .container {
        display: flex;
        gap: 60px;
      }
    }

    .product-page__image-column, .product-page__details-column {
      width: 100%;
    }
  }

  &__image-column {
    min-width: 0;
    width: calc(100% + 30px) !important;
    left: -15px;
    position: relative;
    overflow: hidden;

    .onsale {
      position: absolute !important;
      top: 30px !important;
      left: -50px !important;
      width: 200px !important;
      height: fit-content !important;
      transform: rotate(-45deg);
      background: rgba($black, 0.8) !important;
      color: $white !important;
      border-radius: 0 !important;
      margin: 0 !important;
      min-height: 0 !important;
      font-size: 0.59rem !important;
      font-weight: normal !important;
      letter-spacing: 0.15rem !important;
      text-transform: uppercase !important;
      line-height: 1 !important;
      padding: 10px 0 !important;
    }

    @media (min-width: 1200px) {
      min-width: 650px;
      width: 100% !important;
      left: 0;
      /*position: static;*/
    }
  }

  &__details-column {
    position: relative;
    margin-top: 50px;

    .notice {
      margin-top: 10px;
    }

    .notice__container {
      display: block;
      font-size: 0.82rem !important;
    }

    #wc-stripe-payment-request-wrapper, .StripeElement, .gpay-button, .wc-stripe-elements-field, #payment, #wc-stripe-custom-button {
      display: none !important;
      visibility: hidden !important;
      font-size: 0 !important;
      opacity: 0;
    }

    @media (min-width: 1200px) {
      margin-top: 0;
    }

    .product-rating {
      font-size: 0.65rem !important;
      letter-spacing: 0.075rem !important;
      position: absolute;
      top: 65px;
      right: 0;
      float: none;

      * {
        font-size: 0.65rem !important;
      }

      &__base {
        color: $light-grey !important;
      }

      &__fill {
        color: $beige !important;
      }

      .woocommerce-review-link {
        display: none;
      }

    }

    p > .fw-button {
      display: none;
    }

    h1 {
      font-size: 1.45rem;
      margin: 0 0 25px 0 !important;
    }

    .price {
      font-size: 0.9rem !important;
      font-style: italic;
      color: $black !important;
      margin: 0 !important;

      del, del span {
        color: $dark-grey !important;
        font-size: 0.72rem !important;
        opacity: 1 !important;
      }
    }

    .product-page__short-description {
      font-size: 0.82rem !important;
      border-bottom: 1px solid $light-grey;
      padding-bottom: 15px;
      margin-top: 15px;

      ul {
        @extend .list;
      }

      a {
        color: $black;
      }
    }

    .product-page__designer-link {
      position: relative;
      padding-left: 20px;
      color: $dark-grey;
      text-decoration: none;
      display: flex;

      &:before {
        content: ""; /* Insert bullet symbol */
        position: absolute;
        left: 0; /* Position bullet on the left */

        background-image: url('../images/designer-arrow.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 12px;
        height: 100%;
      }
    }
  }

  &__banner {
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid $light-grey;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    min-height: 82px;
  }

  &__icon {
    display: flex;
    min-width: 35px;
    max-width: 35px;
    width: 35px;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__text {
    font-size: 0.68rem;
    max-width: 330px;

    a {
      color: $black;
    }
  }

  .wlr-product-message {
    font-size: 0.68rem;
    padding: 15px 0 15px 55px !important;
    display: flex;
    align-content: center;
    border-bottom: 1px solid $light-grey;
    background-image: url('../images/checkout-loyalty.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 35px auto;
    min-height: 82px;
  }

  &__parings {
    margin-top: 15px;

    h2 {
      font-size: 0.5rem;
      font-family: $body;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
    }
  }

  &__pared-products {
    display: flex;
    gap: 10px;

    @media (min-width: 750px) {
      gap: 20px;
    }
  }

  &__pared {
    margin-bottom: 15px;
    width: 100%;
    max-width: 120px;
    height: auto;
    display: flex;
    position: relative;
    aspect-ratio: 1/1;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      z-index: 9;
    }

    .product-page__add-to-wishlist {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 99;
      width: 16px;
      height: 16px;
    }

    .product-page__add-to-bag {
      background: $black;
      display: flex;
      position: absolute;
      bottom: 10px;
      left: 10px;
      z-index: 99;
      padding: 5px;

      img {
        width: 15px;
        height: auto;
        margin: 0;
      }
    }
  }

  .woocommerce-variation-add-to-cart, .cart__inner {
    display: flex;
    gap: 0;
    flex-wrap: wrap;

    button {
      background: $white !important;
      border: 1px solid $black !important;
      @extend .button;
      color: $black !important;
      font-family: $body !important;
      border-radius: 0 !important;
      font-size: 0.59rem !important;
      width: 100%;
      padding: 18px !important;
      font-weight: normal !important;
      flex: 1;

      &:hover {
        background: $black !important;
        color: $white !important;
      }
    }
  }

  .variations_form {
    margin-top: 45px !important;

    .variations {
      margin-bottom: 0 !important;

      td {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .reset_variations {
      color: $black;
      font-size: 0.45rem !important;
      text-transform: uppercase;
      text-align: right;
      letter-spacing: 0.15rem;
      text-decoration: none;
      background-image: url('../images/nav-close.svg');
      background-size: 10px auto;
      background-repeat: no-repeat;
      background-position: left center;
      padding: 0 0 0 20px;
      display: inline-block;
      float: right;
      line-height: 1em !important;
      margin: 10px 0 0 0 !important;
    }
  }

  &__wishlist {
    float: right;
    border-left: 1px solid $light-grey;
    padding-left: 15px;
    height: 1.45rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 0;
    width: 50px;
    font-size: 0 !important;

    & > .button {
      display: none !important;
    }

    .add_to_cart_button {
      display: none !important;
    }

    > .wlr-product-message {
      display: none !important;
    }

    .fw-button {
      position: relative;
      display: block;
    }
  }

  .product-card__tools {
    display: none !important;
  }
}

#wll-site-launcher {
  display: none !important;
}

.single-variation-available {

  .variations, .woocommerce-variation {
    display: none !important;

  }

}

.woocommerce div.product form.cart .variations select {
  background: $beige;
}

body.trade .onsale {
  display: none !important;
}

#wc_bis_product_form {
  margin: 50px 0 0 0 !important;

  .wc_bis_form_title {
    font-size: 0.82rem;
  }

  input {
    @extend .input--text;
    padding: 15px 25px !important;
    width: calc(100% - 194px);
  }

  button {
    padding: 22px 45px !important;
    position: relative;
    top: -4px;
  }
}

.wc-tab {
  p {
    a {
      color: $beige !important;
      text-decoration: none !important;
    }
  }
}