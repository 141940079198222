.checkout {
  &__container {
    @extend .container;
    max-width: 1200px;

    h1 {
      font-size: 2.27rem;
      text-transform: uppercase;
      letter-spacing: 0.18rem;
      text-align: center;
      margin: 20px 0 30px 0;
    }

    .woocommerce-form-login-toggle {
      display: none !important;
    }
  }

  &__options {
    display: flex;
    gap: 24px;
    margin-bottom: 100px;

    @media (max-width: 660px) {
      flex-direction: column;
    }
  }

  &__option {
    background: $beige;
    width: 100%;
    padding: 35px;
    text-align: center;

    h2 {
      font-size: 1.45rem;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
    }

    p {
      font-size: 0.68rem;
      margin: 0;

      a, span {
        color: $black;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    .woocommerce-NoticeGroup {
      width: 100%;

      .notice {
        margin-bottom: 0;
      }
    }

    h3 {
      font-size: 1.45rem;
      text-transform: uppercase;
      letter-spacing: 0.24rem;
    }

    input, textarea {
      @extend .input--text;
      border-color: $beige !important;
      padding: 11px 25px !important;
    }

    .required, .optional {
      color: $beige !important;
    }

    select {
      @extend .select--white;
      border: 1px solid $beige !important;
      padding: 11px 25px !important;
    }

    .select2 {
      display: none !important;
    }

    #shipping_country {
      display: block !important;
    }
  }

  &__billing-details {
    width: auto;
    flex: 1;

    .woocommerce-shipping-fields, .woocommerce-account-fields {
      padding: 30px 0;
      border-bottom: 1px solid $beige;

      .create-account {
        padding: 0 !important;
        margin: 0 !important;

        & > .form-row {
          margin-top: 20px !important;
        }

        .password-input {
          input {
            @extend .input--text;

            height: auto !important;
            padding: 11px 25px !important;
            cursor: unset !important;
          }
        }
      }

      #ship-to-different-address, .create-account {
        &:before, &:after {
          display: none !important;
        }

        label {
          display: flex !important;
          align-items: start;
          line-height: 1.7;
        }

        input {
          -webkit-appearance: none !important;
          -moz-appearance: none !important;
          appearance: none !important;
          outline: none !important;

          width: 20px !important;
          height: 20px !important;
          flex: 0 0 auto !important;

          border: 2px solid $beige !important;
          border-radius: 0 !important;
          margin: 8px 30px 0 0 !important;
          padding: 0 !important;
          position: relative !important;
          top: 0 !important;
          left: 0 !important;

          cursor: pointer !important;

          &:before {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;

            width: 12px;
            height: 12px;

            background-color: $beige;
            display: block;
            opacity: 0;

            transition: all 300ms;
          }

          &:checked {
            opacity: 1;

            &:before {
              opacity: 1 !important;
            }
          }

          &:hover {
            &:before {
              opacity: 0.5;
            }
          }
        }
      }

      .shipping_address {
        padding-top: 20px;
      }
    }

    .woocommerce-additional-fields {
      padding-top: 20px;
    }
  }

  &__billing {
    #billing_first_name_field,
    #billing_last_name_field {
      @media (max-width: 800px) {
        width: 100% !important;
      }
    }
  }

  &__your-order {
    width: 100%;
    max-width: 450px;

    @media (max-width: 800px) {
      max-width: none;
    }

    .woocommerce-checkout-payment {
      background: none !important;
      border-radius: 0 !important;

      .methods {
        padding: 0 !important;
        border-bottom: 0 !important;

        .wc_payment_method {
          display: flex !important;
          flex-direction: row;
          justify-content: start;
          align-items: center;
          flex-wrap: wrap;

          label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            line-height: 1;
            padding: 30px 20px 30px 0;

            .stripe-cards-icon {
              margin: 0 !important;
            }
          }

          input {
            -webkit-appearance: none !important;
            -moz-appearance: none !important;
            appearance: none !important;
            outline: none !important;

            width: 20px !important;
            height: 20px !important;
            flex: 0 0 auto !important;
            display: block !important;

            border: 2px solid $beige !important;
            border-radius: 0 !important;
            margin: 0 30px 0 20px !important;
            padding: 0 !important;
            position: relative !important;
            top: 0 !important;
            left: 0 !important;

            cursor: pointer;

            &:before {
              content: "";
              position: absolute;
              top: 2px;
              left: 2px;

              width: 12px;
              height: 12px;

              background-color: $beige;
              display: block;
              opacity: 0;

              transition: all 300ms;
            }

            &:checked {
              opacity: 1;

              &:before {
                opacity: 1 !important;
              }
            }

            &:hover {
              &:before {
                opacity: 0.5;
              }
            }
          }

          .payment_box {
            background-color: $light-beige !important;
            color: $black !important;
            border-radius: 0 !important;
            margin-bottom: 0 !important;

            &:before {
              border: 1em solid $light-beige !important;
              border-right-color: transparent !important;
              border-left-color: transparent !important;
              border-top-color: transparent !important;
            }

            &.payment_method_ppcp-gateway {
              p {
                margin: 0 !important;
              }
            }

            .testmode-info {
              color: $black !important;
              margin: 0 !important;

              a {
                color: $black !important;
                text-decoration: underline !important;
              }
            }

            .woocommerce-SavedPaymentMethods {
              list-style-position: inside;
              padding: 0;

              &-token, &-new {
                display: flex;
                justify-content: start;
                flex-wrap: wrap;
                align-items: center;
                margin: 0 !important;
                padding: 0 20px !important;

                input {
                  -webkit-appearance: none !important;
                  -moz-appearance: none !important;
                  appearance: none !important;
                  outline: none !important;

                  width: 20px !important;
                  height: 20px !important;
                  flex: 0 0 auto !important;
                  display: block !important;

                  border: 2px solid $beige !important;
                  border-radius: 0 !important;
                  margin: 0 30px 0 0 !important;
                  padding: 0 !important;
                  position: relative;
                  top: 0;
                  left: 0;

                  cursor: pointer;

                  &:before {
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 2px;

                    width: 12px;
                    height: 12px;

                    background-color: $beige;
                    display: block;
                    opacity: 0;

                    transition: all 300ms;
                  }

                  &:checked {
                    opacity: 1;

                    &:before {
                      opacity: 1 !important;
                    }
                  }

                  &:hover {
                    &:before {
                      opacity: 0.5;
                    }
                  }
                }

                .stripe-source-errors {
                  width: 100%;
                }
              }

              &-saveNew {
                display: flex;

                input {
                  width: 20px !important;
                  margin-top: 26.5px !important;
                }

                #wc-stripe-new-payment-method {
                  margin-top: 36.5px !important;
                }

                label {
                  line-height: 1.7;
                }
              }
            }

            fieldset {
              border: none !important;
              margin: 0 !important;
              padding: 0 !important;
            }
          }
        }
      }

      .place-order {
        padding: 0 !important;

        label {
          display: flex !important;
          align-items: start;
          margin: 30px 0 !important;
        }

        input {
          -webkit-appearance: none !important;
          -moz-appearance: none !important;
          appearance: none !important;
          outline: none !important;

          width: 20px !important;
          height: 20px !important;
          flex: 0 0 auto !important;
          display: block !important;

          border: 2px solid $beige;
          border-radius: 0 !important;
          margin: 0 30px 0 20px !important;
          padding: 0 !important;
          position: relative;
          top: 6.5px;
          left: 0;

          cursor: pointer;

          &:before {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;

            width: 12px;
            height: 12px;

            background-color: $beige;
            display: block;
            opacity: 0;

            transition: all 300ms;
          }

          &:checked {
            opacity: 1;

            &:before {
              opacity: 1 !important;
            }
          }

          &:hover {
            &:before {
              opacity: 0.5;
            }
          }
        }

        .woocommerce-privacy-policy-text, .woocommerce-terms-and-conditions-checkbox-text {
          line-height: 1.7 !important;

          p {
            margin: 0 !important;
          }

          label {
            line-height: 1.7 !important;
          }

          a {
            color: $beige !important;
            text-decoration: none !important;
            text-transform: lowercase !important;
          }
        }

        #place_order {
          float: none !important;
          width: 100% !important;
        }
      }
    }
  }

  .woocommerce-billing-fields {
    margin-top: 40px;
  }

  &__coupons {
    display: none;
    align-items: stretch;
    align-content: stretch;
    gap: 10px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    input {
      @extend .input--text;
      font-size: 0.56rem !important;
      padding: 10px !important;
      text-transform: uppercase !important;
      text-align: center !important;
      letter-spacing: 0.15rem !important;
      border: 1px solid $beige !important;
      width: 100% !important;
    }

    button {
      @extend .button;
      padding: 15px 30px !important;
      width: 100% !important;
      max-width: 170px !important;
      border: 1px solid $black;
    }
  }

  &__coupon {
    .wp-block-woocommerce-checkout-order-summary-coupon-form-block {
      &:first-child:not(:last-child) {
        display: none !important;
      }
    }
  }

  &__express {
    border: 1px solid $light-grey;
    position: relative;
    padding: 65px 80px 45px 80px;
    margin: 0 0 105px 0;

    @media (max-width: 800px) {
      margin: 0 0 80px;
    }

    @media (max-width: 500px) {
      padding: 65px 15px 45px 15px;
    }

    &:after {
      content: '— or —';
      position: absolute;
      bottom: -70px;
      left: 0;
      right: 0;
      width: 100%;
      font-size: 0.82rem;
      text-align: center;

      @media (max-width: 800px) {
        bottom: -60px;
      }
    }

    &-heading {
      position: absolute;
      top: -0.825rem;
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      align-content: center;
      align-items: center;

      h2 {
        @extend .h1;
        text-transform: uppercase;
        letter-spacing: .18rem;
        font-size: 1.45rem;
        margin: 0 auto;
        padding: 0 40px;
        width: auto;
        background: $white;
        text-align: center;
      }
    }

    &-buttons {
      #wc-stripe-express-checkout-element {
        margin: 0 !important;
      }

      & > p {
        display: none !important;
      }
    }

    /*&-buttons {
      display: flex;
      gap: 20px;
      align-content: center;
      align-items: center;
      justify-content: space-between;
    }

    &-button {
      background: black;
      height: 50px;
      color: $white;
      text-decoration: none;
      width: 100%;
      text-align: center;
      line-height: 2rem;
      border-radius: 5px;
    }*/
  }

  &__payment {
    background: $white !important;
    border-radius: 0 !important;

    &-order {
      #pwgc-redeem-gift-card-form {
        display: none !important;
      }
    }

    &-method {
      font-size: 0.81rem;
      padding: 20px 15px;

      .checkbox__text {
        margin-left: 20px;
      }
    }

    &-order {

    }
  }
}

body.trade .checkout__options {
  display: none !important;
}

.checkout__icon {
  margin-bottom: 15px;

  img {
    display: inline-block;
    height: 35px !important;
    width: auto !important;
    max-width: 35px !important;
    max-height: 35px !important;
  }
}

.woocommerce-privacy-policy-text {
  padding-top: 10px;
  margin-bottom: 30px;

  a {
    color: $beige !important;
    text-decoration: none !important;
  }
}

#ppcp-cancel {
  a {
    color: $beige !important;
    text-decoration: none !important;
  }
}