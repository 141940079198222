.discover {
  height: auto;
  position: relative;
  background: #f2f2f2;

  @media (min-width: 1300px) {
    height: 100vh;
    flex-direction: row;
  }

  &__container {
    @extend .container;
    position: relative;
    z-index: 10;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  &__image {
    // Style for image container
    display: flex;
    overflow: hidden;
    z-index: 5;
    position: relative;
    max-height: 70vh;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 80px;
      background: linear-gradient(0deg, rgba(242, 242, 242, 0) 0%, rgba(242, 242, 242, 1) 100%);
    }

    @media (min-width: 1300px) {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      max-height: none;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: bottom center;
    }
  }

  &__content {
    // Style for content box
    width: 100%;
    text-align: center;
    padding-top: 80px;

    @media (min-width: 1300px) {
      max-width: 505px;
      padding-top: 0;
    }
  }

  &__pre-heading {
    // Style for pre-heading
    font-size: 0.72rem;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.24rem;
    font-weight: 400;
  }

  &__heading {
    // Style for heading
    font-size: 1.45rem;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.24rem;
    padding: 0 75px;
  }

  &__text {
    margin-bottom: 3rem;
  }
}