.product-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 4 equal columns */
  grid-gap: 40px 15px;

  .product-card {
    width: 100%;
  }

  @media (min-width: 750px) {
    grid-gap: 80px 20px;
  }

  @media (min-width: 1000px) {
    grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
  }
}