.kl-private-reset-css-Xuajs1 {
  &[aria-label="POPUP Form"] {
    & > div {
      transform: scale(1) !important;
    }
  }

  .klaviyo-form {
    @media (max-width: 850px) {
      width: calc(100vw - 50px) !important;
    }

    @media (max-width: 650px) {
      padding: 0 !important;
      flex-direction: column-reverse !important;
      max-height: 90vh !important;
      overflow: scroll !important;
    }

    & > div:first-child {
      @media (max-width: 650px) {
        width: calc(100% - 40px) !important;
        padding: 15px 20px !important;
      }

      div[data-testid="form-row"] {
        &:first-child {
          & > div {
            @media (max-width: 650px) {
              padding: 10px 0 !important;
            }
          }

          .ql-font-kanit {
            @media (max-width: 650px) {
              font-size: 48px !important;
            }
          }
        }

        /*
        &:last-child {
          button {
            @extend .button;
            @extend .button--transparent;
          }
        }
        */
      }

      /*
      input {
        padding: 20px 25px !important;
        font-family: $body !important;
        font-size: 1rem !important;
        border: 1px solid $light-beige !important;
        line-height: 1rem !important;
        height: auto !important;
        border-radius: 0 !important;

        &:active, &:focus, {
          border-color: $beige !important;
          outline: none !important;
        }
      }

      button {
        @extend .button;
        @extend .button--beige;

        font-family: $body !important;
        cursor: pointer !important;
        height: auto !important;
      }
      */
    }

    & > div:nth-child(2) {
      @media (max-width: 650px) {
        width: 100% !important;
        max-height: 300px !important;
        min-height: auto !important;
        margin: 0 !important;
        border-radius: 6px 6px 0 0 !important;
      }

      @media (max-width: 380px) {
        max-height: 180px !important;
      }
    }

    img {
      @media (max-width: 650px) {
        object-position: center -100px !important;
      }
    }
  }

  .klaviyo-close-form {
    svg {
      circle {
        @media (max-width: 650px) {
          fill: #ffffff !important;
        }
      }
    }
  }
}