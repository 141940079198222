/** Import theme styles */
@import "../node_modules/slick-carousel/slick/slick.css";
@import "settings/fonts";
@import "settings/colours";
@import "settings/variables";
@import "base/custom";
@import "base/base";
@import "base/type";
@import "components/archive";
@import "components/pre-header";
@import "components/header";
@import "components/footer";
@import "components/input";
@import "components/search";
@import "components/search-pagination";
@import "components/mobile-header";
@import "components/mobile-navigation";
@import "components/overlay";
@import "components/button";
@import "components/dialog";
@import "components/product-card";
@import "components/product-grid";
@import "components/product-gallery";
@import "components/single";
@import "components/teaser";
@import "components/list";
@import "components/table";
@import "components/delivery-costs-table";
@import "components/checkbox";
@import "components/textarea";
@import "components/comment-form";
@import "components/gift-card-field";
@import "components/trade-registration";
@import "components/message";
@import "components/my-account";
@import "components/cookie";
@import "components/design-your-room";

@import "woocommerce/breadcrumb";
@import "woocommerce/category";
@import "woocommerce/filters";
@import "woocommerce/product-page";
@import "woocommerce/quantity";
@import "woocommerce/tabs";
@import "woocommerce/wishlist";
@import "woocommerce/cart-header";
@import "woocommerce/cart";
@import "woocommerce/cart-collaterals";
@import "woocommerce/form-login";
@import "woocommerce/checkout";
@import "woocommerce/checkout-blocks";
@import "woocommerce/review";
@import "woocommerce/review-order";
@import "woocommerce/product-rating";
@import "woocommerce/product-overall-rating";
@import "woocommerce/dashboard";
@import "woocommerce/wployalty";
@import "woocommerce/notice";
@import "woocommerce/payment-methods";
@import "woocommerce/pagination";
@import "woocommerce/klaviyo";

/* Sticking the select styles down here */
@import "components/select";

/*Footer sections*/
@import "sections/usps";
@import "sections/featured-in";
@import "sections/instagram-feed";
@import "sections/our-story";
@import "sections/footer-nav";
@import "sections/hero";
@import "sections/you-might-like";
@import "sections/category-blocks";
@import "sections/what-our-customers-say";
@import "sections/discover";
@import "sections/delivery-header";
@import "sections/delivery-table";
@import "sections/delivery-subheader";
@import "sections/square";
@import "sections/guarantees";
@import "sections/blog-product";
@import "sections/text-block";
@import "sections/pdf-viewer";
@import "sections/product-collection";
@import "sections/design-your-room";


.woocommerce-variation-availability .stock {
  display: none;
}