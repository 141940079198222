.product-overall-ratings {
    flex: 1 0 40%;

    @media (max-width: 1200px) {
        width: 100%;
        max-width: 450px;
        flex: 1 1 auto;
        margin: 0 auto;
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: nowrap;
        gap: 15px;

        * {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: nowrap;
        }
    }

    &__rating {
        flex: 0 0 106px;
    }

    &__pro {
        flex: 1 0 100px;

        .bar {
            height: 7px;
            display: block;
            background-color: $light-grey;

            span {
                background-color: $beige;
                height: 100%;
                display: block;
            }
        }
    }

    &__number {
        font-size: .62rem;
        line-height: 2;
        flex: 0 0 28px;
        text-align: left;
    }
}