.cart-header {
  &__container {
    @extend .container;
    max-width: 1200px;
    text-align: center;
    margin-bottom: 80px;

    @media (max-width: 1000px) {
      margin-bottom: 40px;
    }

    a {
      color: $dark-grey;
      text-decoration: none;
    }
  }

  &__free-shipping {
    background: $light-beige;
    padding: 35px;
    margin-top: 60px;

    @media (max-width: 1000px) {
      margin-top: 40px;
    }

    img {
      width: 48px;
      height: auto;
      margin: 0 auto 15px auto;
      display: block;
    }

    p {
      margin: 0;
    }
  }

  &__progress {
    height: 5px;
    position: relative;
    background: $white;
    width: calc(100% - 32px);
    margin-top: 15px;

    &-bar {
      background: $beige;
      position: absolute;
      top: 0;
      left: 0;
      height: 5px;
    }
  }

  &__discount {
    background: $beige;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    aspect-ratio: 1 / 1;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 0;
    position: absolute;
    top: -13.5px;
    right: -32px;

    img {
      width: 20px;
      max-width: 20px;
      height: auto;
      margin: 0;
    }
  }
}