.checkbox {
  cursor: pointer;

  &__input {
    display: none;

    &:checked + .checkbox__box {
      background-position: center center;
    }
  }

  &__box {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid $beige;
    cursor: pointer;
    background-image: url('../images/checkbox.svg');
    background-position: center -16px;
    background-repeat: no-repeat;
    background-size: 90%;
    transition: all 0.25s ease-in-out;
    position: relative;
    top: 2px;
  }

  &__text {
    font-size: 0.81rem;
  }
}