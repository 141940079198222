.cart-collaterals {
    padding-top: 150px;

    @media (max-width: 1000px) {
        padding-top: 60px;
    }

    .cross-sells {
      width: 100% !important;
      float: none !important;

      h2 {
        text-align: center !important;
        text-transform: uppercase !important;
        margin-bottom: 50px !important;

        @media (max-width: 1000px) {
            margin-bottom: 40px !important;
        }
      }
    }
  }