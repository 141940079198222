.product-gallery {
  &__main {

    .product-gallery__slide {
      height: auto;
      aspect-ratio: 1/1;
      display: flex;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .slick-arrow {
      position: absolute;
      top: 0;
      height: 100%;
      z-index: 10;
      width: 50px;
      font-size: 0;
      display: flex;
      align-items: center;
      padding: 0;
      background: transparent !important;
      border: 0 !important;

      &:before {
        content: '';
        background-color: rgba($white, 0.6);
        width: 100%;
        height: 50px;
        cursor: pointer;
        background-size: 16px auto;
        background-repeat: no-repeat;
        background-position: center;
        aspect-ratio: 1/1;
      }

      &:hover {
        &:before {
          background-color: $white !important;
        }
      }

      &.slick-next {
        right: 0;

        &:before {
          background-image: url('../images/product-carousel-right.svg');
        }
      }

      &.slick-prev {
        left: 0;

        &:before {
          background-image: url('../images/product-carousel-left.svg');
        }
      }
    }
  }

  &__thumbs {
    margin-top: 20px;

    .product-gallery__thumb {
      width: 170px !important;
      height: 170px;
      aspect-ratio: 1/1;
      display: flex;
      margin-right: 20px;
      cursor: pointer;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
}