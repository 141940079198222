.wcf-horizontal-popover {
  border: 0 !important;
  font-size: 0.56rem !important;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  font-weight: normal;
  color: $black;
  background-image: url('../images/icon-filter.svg') !important;
  background-size: 9px auto !important;
  padding-left: 0 !important;
  height: 32px !important;

  &:hover {
    background-color: transparent !important;
  }
}

.wcf-popover-holder {
  .wcf-popover-body {
    font-size: 0.82rem !important;
    border-radius: 0 !important;
  }
}

.wcf-range-thumb {
  background: $black !important;
  width: 12px !important;
  height: 12px !important
}

.wcf-filter-form {
  margin-bottom: 0 !important;
}

.wcf-group-wrapper {
  border-bottom: 1px solid $light-grey;
  padding-bottom: 15px;

  @media (max-width: 460px) {
    border: 0 !important;
    padding: 0 !important;
  }
}

.wcf-active-filter {
  margin-bottom: 0 !important;
}

.woocommerce-result-count {
  margin-bottom: 0 !important;
  font-size: 0.82rem !important;
  color: $dark-grey;
}

.wcf-shop-top {
  margin-top: 15px;
}

.wcf-button.ghost-button {
  font-size: 0.56rem !important;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  font-weight: normal;
  color: $black;

  svg, img {
    display: none;
  }
}

.wcf-filter-title {
  font-size: 0.56rem !important;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  font-weight: normal;
  color: $black;
  font-family: $body;
}


.wcf-mobile-drawer-heading h2 {
  font-size: 0.82em;
  color: $black;
  font-family: $body;
  font-weight: normal !important;
}

.wcf-dropdown-active {
  white-space: wrap !important;
}

.wcf-mobile-actions {
  display: flex;
  gap: 15px;


  .wcf-button {
    @extend .button;
    font-size: .56rem !important;
    border: 1px solid $black !important;
    background: $white !important;
    color: $black !important;
    margin: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    flex: 1 !important;
  }

  .wcf-clear-link {
    @extend .button;
    font-size: .56rem !important;
    color: $black !important;
    margin: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    flex: 1 !important;
  }
}

.wcf-results-count {
  @media (max-width: 460px) {
    display: none;
  }
}