// SCSS for delivery-subheader section

.delivery-subheader {
    @extend .delivery-header;
    padding: 0 0 80px 0;

    &__container {
        @extend .container;
        padding: 0 100px;
    }

    @media (max-width: 1200px) {
        &__container {
            padding: 0 50px;
        }
    }

    @media (max-width: 800px) {
        &__container {
            padding: 0 20px;
        }
    }
}